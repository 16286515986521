import StatisticsCard from "@blocks/StatisticsCard"
import SummaryCard from "@blocks/SummaryCard"
import { useState } from "react"
import Modal from "./Modal"
import Error from "@components/form/Error"

export default function SummaryStatistics({
  statistics,
  childClass,
  data,
  setChildClass,
  error,
  classDropdownShown,
}) {
  const [active, setActive] = useState(0)
  const [openClassModal, setOpenClassModal] = useState(false)

  const handleClassChange = data => {
    setChildClass(data)
    setOpenClassModal(false)
  }

  if (!statistics) return null

  return (
    <div className="grid gap-[24px] items-start">
      <div className="grid gap-[16px]">
        <div className="bg-white rounded-[16px] p-[24px]">
          <div className="flex justify-between">
            <h2 className="text-[16px] mb-[16px]">
              Summary Test / {statistics[active].label}
            </h2>
            {classDropdownShown && (
              <div>
                <div className="grid justify-items-center">
                  <button
                    type="button"
                    className="flex items-center py-[5px] px-[10px] w-[150px] justify-between"
                    onClick={() => setOpenClassModal(true)}
                  >
                    <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {childClass.className}
                    </p>
                    <img
                      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDMzMCAzMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMzMCAzMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggaWQ9IlhNTElEXzIyNV8iIGQ9Ik0zMjUuNjA3LDc5LjM5M2MtNS44NTctNS44NTctMTUuMzU1LTUuODU4LTIxLjIxMywwLjAwMWwtMTM5LjM5LDEzOS4zOTNMMjUuNjA3LDc5LjM5MwoJYy01Ljg1Ny01Ljg1Ny0xNS4zNTUtNS44NTgtMjEuMjEzLDAuMDAxYy01Ljg1OCw1Ljg1OC01Ljg1OCwxNS4zNTUsMCwyMS4yMTNsMTUwLjAwNCwxNTBjMi44MTMsMi44MTMsNi42MjgsNC4zOTMsMTAuNjA2LDQuMzkzCglzNy43OTQtMS41ODEsMTAuNjA2LTQuMzk0bDE0OS45OTYtMTUwQzMzMS40NjUsOTQuNzQ5LDMzMS40NjUsODUuMjUxLDMyNS42MDcsNzkuMzkzeiIgZmlsbD0iIzAwMDAwMCIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
                      alt="arrow down"
                      className="w-[16px] ml-[10px]"
                    />
                  </button>
                </div>
                <Modal
                  isOpen={openClassModal}
                  onRequestClose={() => {
                    setOpenClassModal(false)
                  }}
                  shouldCloseOnOverlayClick={false}
                  closeButtonHidden={true}
                  className="pt-[20px] grid w-[100%] max-w-[500px]"
                >
                  <h2 className="text-primary mb-[10px]">Classes</h2>
                  {data?.data?.map(classItem => (
                    <button
                      key={classItem.id}
                      className="w-[100%] py-[10px] px-[5px] text-left font-light text-dark-grey text-[16px] hover:bg-light-grey focus:bg-light-grey focus:outline-none whitespace-nowrap text-ellipsis overflow-hidden"
                      onClick={() => {
                        handleClassChange({
                          className: classItem.name,
                          classId: classItem.id,
                        })
                      }}
                    >
                      {classItem.name}
                    </button>
                  ))}
                  <button
                    type="button"
                    onClick={() => {
                      setOpenClassModal(false)
                    }}
                    className="text-secondary justify-self-end text-[16px] mt-[10px]"
                  >
                    Cancel
                  </button>
                  {error && <Error message={error} centered />}
                </Modal>
              </div>
            )}
          </div>
          <div className="grid grid-cols-3 gap-[16px]">
            <SummaryCard label="Total" value={statistics[active].overall} />
            <SummaryCard
              label="Attempted"
              value={`${statistics[active].attempted} / ${statistics[active].overall}`}
            />
            <SummaryCard
              label="Remaining"
              value={`${statistics[active].remaining} / ${statistics[active].overall}`}
            />
          </div>
        </div>
      </div>
      <div className="grid gap-[16px]">
        <h2 className="text-[16px] p-[16px] pb-[0px]">Subjects</h2>
        <div className="grid grid-cols-2 gap-[16px]">
          {statistics.map((item, idx) => {
            return (
              <StatisticsCard
                key={idx}
                active={active === idx}
                label={item.label}
                onClick={() => setActive(idx)}
                value={Number(item?.progress?.slice(0, -1))}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
