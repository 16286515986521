import { Link } from "react-router-dom";

export default function BorderlessNavButton({ icon, label, active, to }) {
  return (
    <Link
      to={to}
      className={`
        bg-white/[.1]
        rounded-[4px]
        centered
        cursor-pointer
        hover:bg-white/[.2]
        transition
        flex-col
        hover:text-secondary
        hover:fill-secondary 
        hover:stroke-secondary
        space-y-[4px]
        
        ${active ? `
          text-secondary
          fill-secondary 
          stroke-secondary
        ` : `
          fill-grey 
          stroke-grey
          text-grey
        `}
      `}
    >
      <span>{icon}</span>
      <span>{label}</span>
    </Link>
  )
}
