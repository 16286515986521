import { ReactComponent as ArrowRightIcon } from "@icons/arrow_right.svg"
import React from "react"
import Card from "./Card"

export default React.memo(VectorIconCard)

function VectorIconCard({
  icon,
  children,
  iconHeight,
  iconWidth,
  ...props
}) {
  return (
    <Card
      {...props}
      className="cursor-pointer group relative flex items-center"
    >
      <div
        className={`
        px-[48px] 
        py-[64px] 
        w-full
      `}
      >
        <div
          className={`
          flex 
          flex-col 
          items-center
          group-hover:-translate-y-[24px] 
          transition
        `}
        >
          <div
            className="mb-[16px] h-[30px] w-[40px] bg-secondary group-hover:bg-primary rounded-[4px]"
            style={{
              backgroundImage: `url(${icon})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: iconHeight,
              width: iconWidth
            }}
          />
          <p className="font-semibold text-secondary group-hover:text-primary text-center w-full text-ellipsis overflow-hidden">
            {children}
          </p>
        </div>
        <Arrow />
      </div>
    </Card>
  )
}

function Arrow() {
  return (
    <div
      className={`
      absolute
      opacity-0
      w-[36px]
      h-[36px]
      bg-primary
      rounded-full
      centered
      stroke-white
      group-hover:opacity-100
      group-hover:-translate-y-[32px] 
      transition
      bottom-0
      left-1/2
      -translate-x-1/2
    `}
    >
      <ArrowRightIcon />
    </div>
  )
}
