import { useState } from "react"
import { useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { toast } from "react-toastify"
import Container from "@components/layout/Container"
import NavButton from "@components/navigation/NavButton"
import Error from "@components/form/Error"
import Dropdown from "@blocks/Dropdown"
import Modal from "@blocks/Modal"
import { ReactComponent as LeftArrowIcon } from "@icons/arrow_left.svg"
import { ReactComponent as LogoutIcon } from "@icons/logout.svg"
import { ReactComponent as ProfileIcon } from "@icons/profile.svg"
import { logoutUser } from "@redux/user.slice"
import token from "@constants/token"
import ClassController from "@api/ClassControlle"
import { saveUser, fetchUser } from "@redux/user.slice"

export default function Header({ title, className = "", dropdown, category }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState()
  const [openClassModal, setOpenClassModal] = useState(false)
  const user = useSelector(state => state.user)
  const permanentUser = JSON.parse(localStorage.getItem("permanentUser"))
  const { data } = useQuery(
    `classes-${user?.levelId}`,
    category === "registration" || category === undefined
      ? () => {
          return []
        }
      : () => ClassController.getClasses({ levelId: user?.levelId })
  )

  const logout = async () => {
    if (!window.confirm("Are you sure you want to log out?")) return

    if (category !== "subscription") {
      await dispatch(logoutUser()).unwrap()
      localStorage.removeItem(token)
      localStorage.removeItem("subscription")
      navigate("/login")
    }
    if (category === "subscription") {
      localStorage.removeItem(token)
      localStorage.removeItem("subscription")
      navigate("/login")
    }
  }

  const handleClassChange = async option => {
    try {
      await dispatch(
        saveUser({
          role: user?.role,
          birthday: user?.birthday,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          curriculumId: user?.curriculumId,
          classesId: option,
          levelId: user?.levelId,
        })
      ).unwrap()

      toast.success("Profile updated!")
      setError()
    } catch (err) {
      setError(err.message)
    }

    dispatch(fetchUser())
    setOpenClassModal(false)
  }

  return (
    <Container
      className={twMerge(
        `
        bg-primary 
        rounded-b-[20px]
        py-[20px]
    `,
        className
      )}
    >
      <div className="flex justify-between items-center">
        <div className="w-[300px]">
          {category !== "subscription" && (
            <NavButton
              title="Back"
              icon={<LeftArrowIcon className="w-[18px] h-[18px]" />}
              onClick={() => {
                if (permanentUser?.page) {
                  switch (permanentUser.page) {
                    case "/profile":
                      localStorage.setItem(
                        "permanentUser",
                        JSON.stringify({ ...permanentUser, page: "/" })
                      )
                      break
                    case "/curriculum":
                      localStorage.setItem(
                        "permanentUser",
                        JSON.stringify({ ...permanentUser, page: "/profile" })
                      )
                      break
                    case "/levels":
                      localStorage.setItem(
                        "permanentUser",
                        JSON.stringify({
                          ...permanentUser,
                          page: "/curriculum",
                        })
                      )
                      break
                    case "/classes":
                      localStorage.setItem(
                        "permanentUser",
                        JSON.stringify({
                          ...permanentUser,
                          page: "/levels",
                        })
                      )
                      break
                    default:
                      localStorage.setItem(
                        "permanentUser",
                        JSON.stringify({
                          ...permanentUser,
                          page: "/",
                        })
                      )
                      break
                  }
                } else {
                  navigate(-1)
                }
              }}
            />
          )}
        </div>

        {dropdown ? (
          <Dropdown {...dropdown} dropdownClassName="w-[100%] max-w-[250px]" />
        ) : (
          <h1 className="text-white overflow-hidden whitespace-nowrap text-ellipsis mx-[20px]">
            {title}
          </h1>
        )}

        <div className="grid grid-cols-header items-center gap-[16px] w-[300px]">
          {category !== "subscription" && (
            <>
              {user?.firstName ? (
                <p className="overflow-hidden line-clamp-2 text-ellipsis text-white text-[16px]">
                  {user.firstName} {user?.lastName}
                </p>
              ) : null}
              <NavButton
                title="Profile"
                icon={
                  user?.avatar?.startsWith("data:image") ||
                  user?.avatar?.startsWith("data:text/xml") ? (
                    <img
                      className="w-[56px] h-[56px] rounded-[20px]"
                      src={user.avatar}
                      alt="avatar"
                    />
                  ) : (
                    <ProfileIcon className="w-[22px] h-[22px]" />
                  )
                }
                to="profile"
                active={category === "profile"}
              />
            </>
          )}
          <NavButton
            title="Logout"
            icon={<LogoutIcon className="w-[22px] h-[22px]" />}
            onClick={logout}
          />
        </div>
      </div>
      {category === "home" &&
        user?.role === "student" &&
        window.location.pathname === `${process.env.PUBLIC_URL}/` && (
          <>
            <div className="grid justify-items-center">
              <button
                type="button"
                className="flex items-center py-[5px] px-[10px] w-[150px] justify-between"
                onClick={() => setOpenClassModal(true)}
              >
                <p className="whitespace-nowrap text-ellipsis overflow-hidden text-white">
                  {user.className}
                </p>
                <img
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMzMCAzMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMzMCAzMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGlkPSJYTUxJRF8yMjVfIiBkPSJNMzI1LjYwNyw3OS4zOTNjLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFsLTEzOS4zOSwxMzkuMzkzTDI1LjYwNyw3OS4zOTMNCgljLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFjLTUuODU4LDUuODU4LTUuODU4LDE1LjM1NSwwLDIxLjIxM2wxNTAuMDA0LDE1MGMyLjgxMywyLjgxMyw2LjYyOCw0LjM5MywxMC42MDYsNC4zOTMNCglzNy43OTQtMS41ODEsMTAuNjA2LTQuMzk0bDE0OS45OTYtMTUwQzMzMS40NjUsOTQuNzQ5LDMzMS40NjUsODUuMjUxLDMyNS42MDcsNzkuMzkzeiIgZmlsbD0iI2ZmZmZmZiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo="
                  alt="arrow down"
                  className="w-[16px] ml-[10px]"
                />
              </button>
            </div>
            <Modal
              isOpen={openClassModal}
              onRequestClose={() => {
                setOpenClassModal(false)
              }}
              shouldCloseOnOverlayClick={false}
              closeButtonHidden={true}
              className="pt-[20px] grid w-[100%] max-w-[500px]"
            >
              <h2 className="text-primary mb-[10px]">Classes</h2>
              {data?.data?.map(classItem => (
                <button
                  key={classItem.id}
                  className="w-[100%] py-[10px] px-[5px] text-left font-light text-dark-grey text-[16px] hover:bg-light-grey focus:bg-light-grey focus:outline-none whitespace-nowrap text-ellipsis overflow-hidden"
                  onClick={() => {
                    handleClassChange(classItem.id)
                  }}
                >
                  {classItem.name}
                </button>
              ))}
              <button
                type="button"
                onClick={() => {
                  setOpenClassModal(false)
                }}
                className="text-secondary justify-self-end text-[16px] mt-[10px]"
              >
                Cancel
              </button>
              {error && <Error message={error} centered />}
            </Modal>
          </>
        )}
    </Container>
  )
}
