import range from "@helpers/range"
import when from "@helpers/when"
import { useEffect } from "react"
import { useState } from "react"

export default function PaginationTest({
  total,
  active,
  questions,
  answers,
  onClick,
  setErrorText,
}) {
  const [question, setQuestion] = useState()
  const [answer, setAnswer] = useState()

  useEffect(() => {
    if (questions?.length > 0 && active) {
      setQuestion(questions[active])
    }
  }, [questions, active])

  useEffect(() => {
    if (answers && question) {
      setAnswer(answers[question?.id])
    }
  }, [answers, question])

  return (
    <div className="flex gap-[6px] items-center">
      {range(total).map(idx => {
        return (
          <button
            key={idx}
            onClick={() => {
              answer || answers[questions[idx]?.id] || answers[questions[active]?.id]
                ? onClick?.(idx)
                : setErrorText("Please answer the question")
            }}
            className={`
            w-[24px] 
            h-[24px] 
            rounded-full 
            centered 
            text-[12px]
            transition

            ${when(
              !onClick,
              `
              cursor-auto
            `
            )}

            ${
              idx === active
                ? `text-white bg-primary`
                : `text-primary bg-light-primary`
            }
          `}
            style={{
              fontSize: `${12 * getScaleByIdx(idx, active, total)}px`,
              width: `${24 * getScaleByIdx(idx, active, total)}px`,
              height: `${24 * getScaleByIdx(idx, active, total)}px`,
            }}
          >
            {idx + 1}
          </button>
        )
      })}
    </div>
  )
}

function getScaleByIdx(idx, active, total) {
  const lowest = 0.4

  const measured = idx >= active ? total - active - 1 : active

  const distance = Math.abs(idx - active) / (measured || 1)

  return 1 - (1 - lowest) * distance
}
