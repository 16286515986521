import { ToastContainer } from 'react-toastify'

export default function Toasts(props) {
  return (
    <ToastContainer
      position="bottom-right"
      theme="colored"
      {...props}
    />
  )
}
