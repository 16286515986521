import ClassController from "@api/ClassControlle"
import ClassCard from "@blocks/ClassCard"
import Error from "@components/form/Error"
import Container from "@components/layout/Container"
import useTitle from "@hooks/useTitle"
import { store } from "@redux/store"
import {
  fetchUser,
  mutateAvatar,
  mutateClass,
  mutateSubscription,
  saveUser,
  updateAvatar,
} from "@redux/user.slice"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useState, useEffect } from "react"
import RegisterInfoModal from "@components/modals/RegisterInfoModal"

export default function ClassesPage() {
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const permanentUser = JSON.parse(localStorage.getItem("permanentUser"))
  const [localError, setError] = useState()
  const justLoggedIn = JSON.parse(localStorage.getItem("justLoggedIn"))
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  useEffect(() => {
    if (justLoggedIn === true && permanentUser) {
      setIsInfoModalOpen(true)
    }
  }, [justLoggedIn, permanentUser])

  useTitle(`Registration: ${permanentUser.level.name}`)

  const dispatch = useDispatch()
  const { isLoading, data, error } = useQuery(
    `classes-${permanentUser.level.id}`,
    () => ClassController.getClasses({ levelId: permanentUser.level.id })
  )

  const finish = async item => {
    dispatch(mutateClass(item))

    try {
      await dispatch(
        saveUser({
          role: permanentUser.role,
          birthday: permanentUser.birthday,
          email: permanentUser.email,
          firstName: permanentUser.firstName,
          lastName: permanentUser.lastName,
          levelId: permanentUser.level.id,
          classesId: item.id,
          referralCode: permanentUser.referralCode
            ? permanentUser.referralCode
            : null,
        })
      ).unwrap()

      if (permanentUser.avatar) {
        dispatch(updateAvatar(permanentUser.avatar)).catch(() => mutateAvatar())
      }

      toast.success("Thank you for registration!")

      dispatch(fetchUser())
    } catch (err) {
      setError(err.message)
      if (err.message === "Referral code not found") {
        setTimeout(() => navigate("/registration/profile"), 5000)
      }
    }
  }

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (user?.role === "student") {
    navigate("/")
  }

  if (!permanentUser?.role) {
    return <Navigate to="/registration" />
  } else if (
    permanentUser?.role === "student" &&
    !permanentUser?.subscription
  ) {
    return <Navigate to="/subscription" />
  } else if (
    permanentUser?.role === "student" &&
    permanentUser?.subscription === true &&
    permanentUser?.page &&
    permanentUser?.page !== "/classes"
  ) {
    return <Navigate to={"/registration" + permanentUser?.page} />
  } else {
    return (
      <>
        <RegisterInfoModal
          permanentUser={permanentUser}
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={setIsInfoModalOpen}
        />
        <Container.Narrow className="h-full">
          <PageTemplate
            title={`Choose your ${permanentUser?.level.name} classes`}
          >
            {Boolean(data?.data?.length) ? (
              <div className="grid gap-[24px]">
                <div className="grid grid-cols-3 gap-[24px] items-start">
                  {data.data.map(item => (
                    <ClassCard key={item.id} onClick={() => finish(item)}>
                      {item.name}
                    </ClassCard>
                  ))}
                </div>
                {localError && <Error message={localError} centered />}
              </div>
            ) : (
              <p className="text-center">
                <small>
                  The list is empty. Please, choose a different level.
                </small>
              </p>
            )}
          </PageTemplate>
        </Container.Narrow>
      </>
    )
  }
}
