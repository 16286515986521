import role from "@constants/role"
import UnexpectedRoleError from "@errors/UnexpectedRoleError"
import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import token from "@constants/token"
import { useEffect, useRef, useState } from "react"
import axios from "axios"

export default function StudentMiddleware() {
  const user = useSelector(state => state.user)
  let localToken = useRef()
  const [profileExist, setProfileExist] = useState()

  const checkHasProfile = async () => {
    const res = await axios.get("/user/profile/student/exist")
    setProfileExist(res?.data?.data)
  }

  useEffect(() => {
    if (profileExist === undefined) {
      checkHasProfile()
    }
  }, [profileExist])

  useEffect(() => {
    localToken.current = localStorage.getItem(token)
  }, [user])

  if (!user) return <Navigate to="/login" />

  if (!user && !localToken?.current) return <Navigate to="/login" />

  if (user?.subscription === false && user?.role === "student")
    return <Navigate to="/subscription" />

  if (
    user?.subscription === false &&
    user?.record === false &&
    profileExist === true
  ) {
    return <Navigate to="/subscription" />
  }

  if (user?.record === false && profileExist === false)
    return <Navigate to="/registration" />

  if (profileExist === true) {
    switch (user?.role) {
      case role.parent:
        return <Navigate to="/parent" />
      case role.student:
        return <Outlet />
      default:
        throw new UnexpectedRoleError(user?.role)
    }
  }
}
