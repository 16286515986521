import { forwardRef } from "react"
import { twMerge } from "tailwind-merge"
import Error from "./Error"

export default forwardRef(TextArea)

function TextArea({ className, error, ...props }, ref) {
  return (
    <div className="w-full">
      <textarea
        className={twMerge(
          "bg-[#F1F1F1] rounded-[16px] w-full p-[24px] placeholder:font-medium",
          className
        )}
        ref={ref}
        {...props}
      />
      {error && <Error message={error.message} />}
    </div>
  )
}
