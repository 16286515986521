import Avatar from "@components/form/Avatar"
import Button from "@components/form/Button"
import Datepicker from "@components/form/Datepicker"
import Error from "@components/form/Error"
import Input from "@components/form/Input"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import RegisterInfoModal from "@components/modals/RegisterInfoModal"
import role from "@constants/role"
import capitalize from "@helpers/capitalize"
import readFileAsDataUrl from "@helpers/readFileAsDataUrl"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import {
  fetchUser,
  mutateAvatar,
  saveUser,
  updateAvatar,
} from "@redux/user.slice"
import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"

export default function ProfilePage() {
  const permanentUser = JSON.parse(localStorage.getItem("permanentUser"))
  const justLoggedIn = JSON.parse(localStorage.getItem("justLoggedIn"))
  const user = useSelector(state => state.user)
  const [error, setError] = useState()
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  useTitle(
    `Registration: ${capitalize(
      permanentUser?.role ? permanentUser?.role : ""
    )}`
  )
  useHeader({ className: "rounded-b-none" }, [])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (justLoggedIn === true && permanentUser) {
      setIsInfoModalOpen(true)
    }
  }, [justLoggedIn, permanentUser])

  const next = async data => {
    data.avatar = await readFileAsDataUrl(data.avatar?.[0])
    localStorage.setItem(
      "permanentUser",
      JSON.stringify({
        ...permanentUser,
        ...data,
        page: permanentUser?.role === "student" ? "/curriculum" : null,
        parentsCode: permanentUser?.parentsCode
          ? permanentUser?.parentsCode
          : null,
      })
    )
    switch (permanentUser?.role) {
      case role.parent:
        try {
          await dispatch(
            saveUser({
              role: permanentUser?.role,
              birthday: data.birthday,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              parentsCode: permanentUser?.parentsCode,
            })
          ).unwrap()
        } catch (err) {
          setError(err.message)
          break
        }

        if (data.avatar) {
          dispatch(updateAvatar(data.avatar))
            .unwrap()
            .catch(() => dispatch(mutateAvatar()))
        }

        toast.success("Thank you for registration!")
        dispatch(fetchUser())
        navigate("/parent")
        break
      case role.student:
        navigate("/registration/curriculum")
        break
      default:
        console.log("something went wrong")
        break
    }
  }

  if (user?.role === "student") {
    return <Navigate to="/" />
  }

  if (!permanentUser?.role) {
    return <Navigate to="/registration" />
  } else if (
    permanentUser?.role === "student" &&
    !permanentUser?.subscription
  ) {
    return <Navigate to="/subscription" />
  } else if (
    permanentUser?.role === "student" &&
    permanentUser?.subscription === true &&
    permanentUser?.page &&
    permanentUser?.page !== "/profile"
  ) {
    return <Navigate to={"/registration" + permanentUser?.page} />
  } else if (permanentUser?.role) {
    return (
      <>
        <RegisterInfoModal
          permanentUser={permanentUser}
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={setIsInfoModalOpen}
        />
        <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
          <HeaderSubmenu
            className="relative h-[84px]"
            center={
              <div className="absolute z-10 left-1/2 top-0 -translate-x-1/2">
                <Avatar
                  name="avatar"
                  className="mx-auto"
                  error={errors?.avatar}
                  defaultAvatar={permanentUser.avatar}
                  {...register("avatar")}
                />
              </div>
            }
          />
          <Container.XS className="h-full">
            <form
              className="h-full flex flex-col relative"
              onSubmit={handleSubmit(next)}
            >
              <div className="h-full overflow-auto mt-[96px] mb-[36px]">
                <div className="grid gap-[32px] h-full content-center px-[2px]">
                  <div className="grid gap-[24px]">
                    <div className="grid grid-cols-2 gap-[24px]">
                      <Input
                        placeholder="Name"
                        defaultValue={permanentUser?.firstName}
                        error={errors?.firstName}
                        {...register("firstName", {
                          required: {
                            message: "Name is required",
                            value: true,
                          },
                          minLength: {
                            message:
                              "Name's length must be more than 1 character.",
                            value: 2,
                          },
                          maxLength: {
                            message:
                              "Name's length must be less than 51 characters.",
                            value: 50,
                          },
                        })}
                      />
                      <Input
                        placeholder="Last name"
                        defaultValue={permanentUser?.lastName}
                        error={errors?.lastName}
                        {...register("lastName", {
                          required: {
                            message: "Last name is required",
                            value: true,
                          },
                          minLength: {
                            message:
                              "Last name's length must be more than 1 character.",
                            value: 2,
                          },
                          maxLength: {
                            message:
                              "Last name's length must be less than 51 characters.",
                            value: 50,
                          },
                        })}
                      />
                    </div>
                    <div className="grid gap-[24px]">
                      <Datepicker.Controller
                        name="birthday"
                        placeholder="Date of birth"
                        isClearable
                        defaultValue={permanentUser?.birthday}
                        error={errors?.birthday}
                        control={control}
                        rules={{
                          required: {
                            message: "Date of birth is required",
                            value: true,
                          },
                        }}
                      />
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email address"
                        error={errors?.email}
                        defaultValue={permanentUser?.email}
                        {...register("email", {
                          required: {
                            message: "Email address is required",
                            value: true,
                          },
                        })}
                      />
                      {permanentUser?.role === "student" && (
                        <Input
                          name="referralCode"
                          type="text"
                          placeholder="Referral Code (optional value)"
                          error={errors?.referralCode}
                          defaultValue={permanentUser?.referralCode}
                          {...register("referralCode", {
                            minLength: {
                              message:
                                "Referral Code must be more than 3 character.",
                              value: 4,
                            },
                            maxLength: {
                              message:
                                "Referral Code must be less or equal than 20 characters.",
                              value: 20,
                            },
                          })}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="flex flex-col items-center">
                      <Button>Save</Button>
                      {error && <Error message={error} centered />}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Container.XS>
        </div>
      </>
    )
  }
}
