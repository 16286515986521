import Button from "@components/form/Button"
import InputOtp from "@components/form/InputOtp"
import Container from "@components/layout/Container"
import useTitle from "@hooks/useTitle"
import PageTemplate from "@templates/PageTemplate"
import { useForm } from "react-hook-form"
import { useNavigate, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import RegisterInfoModal from "@components/modals/RegisterInfoModal"

export default function ParentsCodePage() {
  useTitle("Parents Code")

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const permanentUser = JSON.parse(localStorage.getItem("permanentUser"))
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const justLoggedIn = JSON.parse(localStorage.getItem("justLoggedIn"))
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  useEffect(() => {
    if (justLoggedIn === true && permanentUser) {
      setIsInfoModalOpen(true)
    }
  }, [justLoggedIn, permanentUser])

  const next = data => {
    localStorage.setItem(
      "permanentUser",
      JSON.stringify({ ...permanentUser, parentsCode: data?.parentsCode })
    )
    navigate("/registration/profile")
  }

  if (!permanentUser) {
    return <Navigate to="/registration" />
  }

  if (user?.role === "parent") {
    return <Navigate to="/parent" />
  } else if (user?.role === "student") {
    return <Navigate to="/" />
  } else {
    return (
      <>
        <RegisterInfoModal
          permanentUser={permanentUser}
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={setIsInfoModalOpen}
        />
        <Container.Narrow className="h-full">
          <PageTemplate title="Enter the parents code here:">
            <form
              className="flex flex-col items-center gap-[48px]"
              onSubmit={handleSubmit(next)}
            >
              <div className="grid gap-[24px]">
                <InputOtp.Controller
                  defaultValue={permanentUser?.parentsCode}
                  length={6}
                  centered
                  control={control}
                  error={errors?.parentsCode}
                  autoFocus
                  name="parentsCode"
                  rules={{
                    required: {
                      message: "The code is required",
                      value: true,
                    },
                    minLength: lengthRule,
                    maxLength: lengthRule,
                  }}
                />
                <p className="text-center">
                  <small className="text-dark-grey opacity-80">
                    You can get this code from your child's app in his/her
                    profile section
                  </small>
                </p>
              </div>
              <Button>Next</Button>
            </form>
          </PageTemplate>
        </Container.Narrow>
      </>
    )
  }
}

const lengthRule = {
  message: "The code should consist of 6 characters",
  value: 6,
}
