import ErrorCard from "@blocks/ErrorCard"
import Container from "@components/layout/Container"
import PageTemplate from "./PageTemplate"

export default function ErrorPageTemplate({ children, title, card, onClick }) {
  return (
    <Container.XS className="h-full">
      <PageTemplate>
        <ErrorCard title={title} className="mt-[24px]" onClick={onClick} {...card}>
          {children}
        </ErrorCard>
      </PageTemplate>
    </Container.XS>
  )
}
