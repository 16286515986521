import { useEffect, useRef } from "react";

/**
 * The effect that doesn't run the first time.
 */
export default function useUpdateEffect(effect, deps) {
  const componentJustMounted = useRef(true)

  useEffect(() => {
    return () => {
      componentJustMounted.current = true
    }
  }, [])

  useEffect(() => {
    if (componentJustMounted.current) {
      componentJustMounted.current = false
      return
    }

    return effect()
  }, deps)
}