import Avatar from "@components/blocks/Avatar"
import ChildCard from "@components/blocks/ChildCard"
import Button from "@components/form/Button"
import ButtonAdd from "@components/form/ButtonAdd"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import useCategory from "@hooks/useCategory"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import { fetchUser, saveChild } from "@redux/user.slice"
import PageTemplate from "@templates/PageTemplate"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

export default function SummaryPage() {
  useTitle("Summary")
  useCategory("summary")
  useHeader({ className: "rounded-b-none" }, [])

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  localStorage.removeItem("permanentUser")
  localStorage.removeItem("justLoggedIn")

  const addChild = async () => {
    const parentsCode = window.prompt("Provide a parents code:")
    if (!parentsCode) return

    try {
      await dispatch(saveChild(parentsCode)).unwrap()
      await dispatch(fetchUser()).unwrap()
      toast.success("The child is added successfully")
    } catch (err) {
      toast.error(err.message)
    }
  }

  return (
    <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
      <HeaderSubmenu
        className="relative h-[84px]"
        center={
          <div className="absolute z-10 left-1/2 top-[1px] -translate-x-1/2">
            <div className="flex flex-col items-center gap-[24px]">
              <Avatar avatar={user.avatar} size="160px" />
              <div className="text-center">
                <h2 className="text-primary font-semibold">
                  {user.firstName} {user.lastName}
                </h2>
                <p className="text-dark-grey font-normal opacity-80">
                  Parent Profile
                </p>
              </div>
            </div>
          </div>
        }
      />
      <Container.Medium className="h-full pt-[160px]">
        <PageTemplate>
          <div>
            <div className="grid grid-cols-2 gap-[24px] items-start mb-[64px]">
              <div className="grid gap-[16px]">
                <div className="grid gap-[4px]">
                  <p>
                    <small className="text-dark-grey opacity-80">Phone</small>
                  </p>
                  <p>{user.msisdn}</p>
                </div>
                <div className="grid gap-[4px]">
                  <p>
                    <small className="text-dark-grey opacity-80">Email</small>
                  </p>
                  <p>{user.email}</p>
                </div>
                <div className="grid gap-[4px]">
                  <p>
                    <small className="text-dark-grey opacity-80">
                      Birthday
                    </small>
                  </p>
                  <p>{user.birthday}</p>
                </div>
              </div>
              <div className="grid gap-[16px] bg-white rounded-[16px] p-[24px]">
                <h3>Children</h3>
                {user.children.map(child => (
                  <ChildCard
                    key={child.uuid}
                    to={`children/${child.uuid}/summary`}
                    photo={child.avatar}
                    name={`${child.firstName} ${child.lastName}`}
                    level={child.levelName}
                    class={child.className}
                  />
                ))}
                <ButtonAdd onClick={addChild}>Add a child</ButtonAdd>
              </div>
            </div>
            <div className="flex justify-center">
              <Link to="profile">
                <Button>Edit</Button>
              </Link>
            </div>
          </div>
        </PageTemplate>
      </Container.Medium>
    </div>
  )
}
