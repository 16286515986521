import { setTitle } from "@redux/app.slice"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

export default function useTitle(title) {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = title 
            ? `E-learning: ${title}` 
            : `E-learning`
            
        dispatch(setTitle(title))
    }, [title])
}