import when from "@helpers/when";

export default function Indicator({ focus, input, error, disabled }) {
    return (
      <div
        className={`
          absolute 
          w-[14px] 
          h-[14px] 
          rounded-full 
          top-1/2 
          left-[16px] 
          -translate-y-1/2
          
          ${ when(disabled, `bg-dark-grey`)
          || when(focus || input, `bg-black`) 
          || when(error, `bg-red`) 
          || `bg-grey` }
      `}
      ></div>
    )
  }
  