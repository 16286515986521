import ParentMiddleware from "@middleware/ParentMiddleware";
import MainTemplate from "@templates/MainTemplate";
import { Route, Routes } from "react-router-dom";
import ProfilePage from "./pages/ProfilePage";
import SummaryChildPage from "./pages/SummaryChildPage";
import SummaryPage from "./pages/SummaryPage";

export default function ParentRoutes() {
  return (
    <Routes>
      <Route element={<ParentMiddleware />}>
        <Route element={<MainTemplate />}>
          <Route index element={<SummaryPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="children/:uuid/summary/*" element={<SummaryChildPage />} />
        </Route>
      </Route>
    </Routes>
  )
}