import CornerLoading from "@components/loading/CornerLoading"
import FullPageLoading from "@components/loading/FullPageLoading"
import useAuth from "@hooks/useAuth"
import LoginRoutes from "@login/routes"
import ParentRoutes from "@parent/routes"
import RegistrationRoutes from "@registration/routes"
import StudentRoutes from "@student/routes"
import { useSelector } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import SubscriptionRoutes from "./domains/subscription/routes"
import ContactUsroutes from "./domains/contactUs/routes"
import PrivacyPolicyroutes from "./domains/privacyPolicy/routes"
import TermsAndConditionsroutes from "./domains/termsAndConditions/routes"

export default function App() {
  const status = useAuth()
  const loading = useSelector(state => state.app.loading)

  if (status !== "completed") return <FullPageLoading />

  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="login/*" element={<LoginRoutes />} />
          <Route path="registration/*" element={<RegistrationRoutes />} />
          <Route path="parent/*" element={<ParentRoutes />} />
          <Route path="*" element={<StudentRoutes />} />
          <Route path="subscription" element={<SubscriptionRoutes />} />
          <Route path="Contact-Us/*" element={<ContactUsroutes />} />
          <Route path="PrivacyPolicy/" element={<PrivacyPolicyroutes/>} />
          <Route path="Terms&Conditions/" element={<TermsAndConditionsroutes/>} />
        </Routes>
      </BrowserRouter>
      <CornerLoading visible={loading > 0} />
    </div>
  )
}
