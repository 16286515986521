import React, { useEffect, useState } from "react";
import NewHeader from "@components/layout/NewHeader";
import { getTermsAndConditions } from "@login/services";
import { useNavigate } from "react-router-dom";

export default function TermsAndConditionPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState("");

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      setLoading(true);
      const res = await getTermsAndConditions();
      if (res && res.data) {
        setTermsAndConditions(res.data.replace(/\n/g, "<br />"));
      } else {
        setTermsAndConditions("Unexpected error observed. Try again later.");
      }
      setLoading(false);
    };
    fetchTermsAndConditions();
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <NewHeader />
      <div className="container mx-auto lg:p-10 flex-grow">
        <div className="container mx-auto p-10 flex flex-col place-items-center">
          <div className="text-center font-semibold lg:text-6xl text-3xl my-3">
            Terms and Conditions
          </div>
          {loading ? (
            <div className="text-center font-semibold text-[#49A259] my-3">
              Loading Please wait...
            </div>
          ) : (
            <div className="container mx-auto lg:p-5 my-10 grid grid-cols-none gap-y-2">
              <div
                className="text-sm text-left text-dark-grey"
                dangerouslySetInnerHTML={{ __html: termsAndConditions }}
              ></div>
            </div>
          )}
        </div>
      </div>
      <footer className="mt-auto">
        <hr className="border-[#F1F1F3]" />
        <div className="flex flex-row justify-between p-5">
          <div className="text-[14px] text-[#656567]">© 2024 Innovii. All rights reserved.</div>
          <div className="flex gap-5">
            <button onClick={() => navigate("/PrivacyPolicy")} className="text-[14px] text-[#656567]">Privacy Policy</button>
            <button onClick={() => navigate("/Terms&Conditions")} className="text-[14px] text-[#656567]">Terms of Service</button>
          </div>
        </div>
      </footer>
    </div>
  );
}
