import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import token from "@constants/token"
import { useEffect, useRef } from "react"

export default function GuestMiddleware() {
  const user = useSelector(state => state.user)

  let localToken = useRef()

  useEffect(() => {
    localToken.current = localStorage.getItem(token)
  }, [user])

  if (!user && localToken?.current) return <Navigate to="/registration" />
  if (user) return <Navigate to="/" />

  return <Outlet />
}
