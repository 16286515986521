import { twMerge } from "tailwind-merge";

export default function Badge({ color, children, className }) {
  return (
    <div 
      className={twMerge(`
        py-[4px]
        px-[12px]
        border
        border-solid
        rounded-full
        flex
        items-center
        w-fit
      `, className)} 
      style={{
        borderColor: color,
        color
      }}
    >
      <div className="mr-[6px] relative">
        <div className="rounded-full w-[12px] h-[12px] opacity-[.15]" style={{ backgroundColor: color }} />
        <div className="absolute absolute-centered rounded-full w-[6px] h-[6px]" style={{ backgroundColor: color }} />
      </div>
      {children}
    </div>
  )
}

Badge.Fill = function({ backgroundColor, className, color, children, ...props }) {
  return (
    <div 
      className={twMerge(`
        bg-primary
        text-white
        py-[4px]
        px-[12px]
        rounded-full
        flex
        items-center
        w-fit
      `, className)} 
      style={{ backgroundColor, color }}
      {...props}
    >
      {children}
    </div>
  )
}