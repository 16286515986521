import React, { useEffect, useState } from "react";
import NewHeader from "@components/layout/NewHeader";
import { getPrivacyPolicy } from "@login/services";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicyPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      setLoading(true);
      const res = await getPrivacyPolicy();
      if (res && res.data) {
        setPrivacyPolicy(res.data);
      } else {
        setPrivacyPolicy("Failed to load privacy policy");
      }
      setLoading(false);
    };
    fetchPrivacyPolicy();
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <NewHeader />
      <div className="container mx-auto lg:p-10 flex-grow">
        {loading ? (
          <div className="text-center font-semibold text-[#49A259] my-3">
            Loading Please wait...
          </div>
        ) : (
          <div className="container mx-auto p-5 lg:my-5 my-2 grid grid-cols-none gap-y-2">
            <div
              className="text-sm text-left text-dark-grey"
              dangerouslySetInnerHTML={{ __html: privacyPolicy }}
            ></div>
          </div>
        )}
      </div>
      <footer className="mt-auto">
        <hr className="border-[#F1F1F3]" />
        <div className="flex flex-row justify-between p-5">
          <div className="text-[14px] text-[#656567]">© 2024 Innovii. All rights reserved.</div>
          <div className="flex gap-5">
            <button onClick={() => navigate("/PrivacyPolicy")} className="text-[14px] text-[#656567]">Privacy Policy</button>
            <button onClick={() => navigate("/Terms&Conditions")} className="text-[14px] text-[#656567]">Terms of Service</button>
          </div>
        </div>
      </footer>
    </div>
  );
}
