import Badge from "./Badge"
import Card from "./Card"
import { ReactComponent as ArrowRightIcon } from "@icons/arrow_right.svg"
import React from "react"

import RateIcon from "@icons/rate.svg"

export default React.memo(LessonCard)

const colorcoded = {
  COMPLETED: "#28A9D1",
  ON_GOING: "#F6C53E",
}

function LessonCard({
  illustration,
  title,
  description,
  status,
  to,
  rating,
  level,
  subject,
}) {
  return (
    <Card to={to} className="rounded-[20px] relative">
      {rating && (
        <span className="flex items-center bg-yellow px-[12px] py-[3px] rounded-[50px] absolute top-[10px] right-[10px]">
          <img alt="rate" className="w-[19px] h-[19px]" src={RateIcon} />
          <span className="ml-[6px] text-white text-[14px] leading-[14px]">
            {rating}
          </span>
        </span>
      )}

      <div className="p-[10px] flex items-stretch min-h-[140px] h-[100%]">
        <div
          className="w-[100px] flex-shrink-0 bg-grey rounded-[10px]"
          style={{
            backgroundImage: `url(${illustration})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        />
        <div className="flex items-center w-full px-[24px] pt-[30px] pb-[16px]">
          <div className="w-full">
            <h3>{title}</h3>
            <div className="flex justify-between py-[5px] min-h-[20px]">
              <small className="overflow-hidden line-clamp-2 text-ellipsis text-typography-grey font-semibold px-[4px] w-[49%] self-center ">
                {level}
              </small>
              <div className="min-h-[20px] h-[100%] w-[1px] bg-typography-grey self-center" />
              <small className="overflow-hidden line-clamp-2 text-ellipsis text-typography-grey font-semibold px-[2px] w-[49%] self-center">
                {subject}
              </small>
            </div>

            <p>
              <small className="overflow-hidden line-clamp-3 text-ellipsis">
                {description}
              </small>
            </p>
            {status && (
              <Badge color={colorcoded[status]} className="mt-[8px]">
                {status}
              </Badge>
            )}
          </div>
          <div className="stroke-primary flex-shrink-0 py-[8px]">
            <ArrowRightIcon className="w-[18px] h-[18px]" />
          </div>
        </div>
      </div>
    </Card>
  )
}
