import axios from "axios"

export default class TestController {
  static async getParentSummary({ childMsisdn, classesId, subjectId }) {
    const result = await axios.post('/user/test/parent/summary', { childMsisdn, classesId, subjectId })
    return result.data
  }

  static async getParentOverallSummary({ childMsisdn, classesId }) {
    const result = await axios.post('/user/test/parent/summary/overall', { childMsisdn, classesId })
    return result.data
  }

  static async getOverallSummary() {
    const result = await axios.post('/user/test/summary/overall', {})
    return result.data
  }

  static async getSummary({ subjectId }) {
    const result = await axios.post('/user/test/summary', { subjectId })
    return result.data
  }

  static async getTests({ length, start = 0 } = {}) {
    const result = await axios.post('/user/test/list', { length, start })
    return result.data
  }

  static async submitAnswer({ optionId }) {
    const result = await axios.post('/user/test/answer/submit', { optionId })
    return result.data
  }

  static async startTest({ lessonId }) {
    const result = await axios.post('/user/test/start', { lessonId })
    return result.data
  }

  static async finishTest({ lessonId }) {
    const result = await axios.post('/user/test/finish', { lessonId })
    return result.data
  }

  static async getResult({ lessonId }) {
    const result = await axios.post('/user/test/result', { lessonId })
    return result.data
  }

  static async isOngoing({ lessonId }) {
    const result = await axios.post('/user/test/is-ongoing', { lessonId })
    return result.data
  }
}