export const isEmpty = (item) => {
  if (typeof item === "string") {
    if (item?.length === 0) return true;
    if (item?.length !== 0) return false;
  }
  if (!item || typeof item !== "object") return true;
  if (Array.isArray(item)) {
    return item?.length > 0 ? false : true;
  }
  return !Object.keys(item)?.length;
};


export const getResData = (res, inCatch = false) => {
  const responseData = {
    success: false,
    result: null,
    data: null,
    error: null,
    total: null,
  };

  if (inCatch) {
    if (res && typeof res === "object" && isEmpty(res)) {
      responseData.error = res.toString();
    } else {
      responseData.error = res;
    }
    return responseData;
  }

  if (isEmpty(res)) return responseData;
  if (res.result === "error") {
    responseData.error = res.errorDesc;
    return responseData;
  }
  if (res.data && res.result) {
    responseData.success = true;
    responseData.result = res.result;
    responseData.data = res.data;
    responseData.total = res.total;
    return responseData;
  }
  return responseData;
};