import React from 'react'
import GuestMiddleware from "@middleware/GuestMiddleware"
import { Route, Routes } from "react-router-dom"
import TermsAndConditionPage from './pages/TermsAndConditionPage'

export default function TermsAndConditionsroutes() {
  return (
    <Routes>
    <Route element={<GuestMiddleware />}>
      <Route index element={<TermsAndConditionPage/>} />
    </Route>
  </Routes>
  )
}
