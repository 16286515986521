import axios from "axios"

export default class BookLibraryController {
  static async getBooks({ name, length, start = 0 }) {
    const result = await axios.post('/user/books/list', { length, start, name })
    return result.data
  }

  static async getBook({ uuid }) {
    const result = await axios.get(`/user/book/get/${uuid}`)
    return result.data
  }
}