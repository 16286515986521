import { ReactComponent as MicroscopeIcon } from "@icons/microscope.svg"
import React from "react"
import Card from "./Card"

export default React.memo(SubjectCard)

function SubjectCard({ to, illustration, children, backgroundColor, color }) {
  return (
    <Card
      to={to}
      className={`
        h-[120px]
        p-[16px]
        rounded-[16px]
        bg-primary
        flex
        items-end
        justify-center
      `}
      style={{
        backgroundColor,
        backgroundImage: `url(${illustration})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      }}
    >
      <div className="bg-white flex items-center pl-[4px] pr-[8px] rounded-full py-[3px]">
        <div className="rounded-full centered bg-light-primary fill-primary h-[24px] w-[24px] flex-shrink-0" style={{ backgroundColor, fill: color }}>
          <MicroscopeIcon />
        </div>
        <span className="text-primary ml-[12px]" style={{ color }}>{children}</span>
      </div>
    </Card>
  )
}
