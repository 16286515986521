import { ReactComponent as FileIcon } from "@icons/file.svg"
import { ReactComponent as ArrowRightIcon } from "@icons/arrow_right.svg"
import Card from "./Card"
import React from "react"

export default React.memo(YearCard)

function YearCard({ to, children }) {
  return (
    <Card
      to={to}
      className={"rounded-[16px]"}
    >
      <div className="flex py-[36px] px-[24px] justify-between items-center">
        <div className="flex items-center">
          <div className="rounded-full centered bg-light-secondary fill-secondary h-[36px] w-[36px] flex-shrink-0">
            <FileIcon />
          </div>
          <span className="ml-[24px]">{children}</span>
        </div>
        <ArrowRightIcon className="stroke-primary w-[18px] h-[18px]" />
      </div>
    </Card>
  )
}
