import axios from "axios";

export default class SignInController {
  static async logout() {
    const result = await axios.post('/user/logout')
    localStorage.removeItem("subscription")
    return result.data
  }

  static async login({ msisdn }) {
    const result = await axios.post('/login', { msisdn })
    return result.data
  }

  static async loginConfirm({ msisdn, code }) {
    const result = await axios.post('/login/confirm', { msisdn, code })
    return result.data
  }
}