import loginBackgroundIllustration from "@illustrations/login_background.jpg";
import Button from '@components/form/Button'

export default function UnauthenticatedTemplate({ children, onSubmit, title, description, continueText }) {
  return (
    <div className="h-screen bg-grey flex items-end justify-center p-[48px]" style={{
      backgroundImage: `url(${loginBackgroundIllustration})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}>
      <form className="flex flex-col items-center gap-[16px] max-w-[320px]" onSubmit={onSubmit}>
        <div className="bg-white p-[24px] rounded-[8px]">
          <div className="text-center mb-[24px]">
            <h1 className="text-[16px] mb-[4px]">{title}</h1>
            <p className="text-dark-grey opacity-80 font-normal text-[12px]">{description}</p>
          </div>
          {children}
        </div>
        <Button className="w-full">{continueText || 'Next'}</Button>
      </form>
    </div>
  )
}