import { ReactComponent as RefreshIcon } from "@icons/refresh.svg"
import { forwardRef, useState } from "react"
import ReactSelect from "react-select"
import Indicator from "./Indicator"
import { Controller } from "react-hook-form"
import Error from "./Error"
import combineFn from "@helpers/combineFn"
import { useEffect } from "react"

const Select = forwardRef(
  ({ onChange, onFocus, onBlur, error, ...props }, ref) => {
    const [option, setOption] = useState(props.value || props.defaultValue)
    const [focus, setFocus] = useState(false)

    useEffect(() => {
      setOption(props.value)
    }, [props.value])

    const handleChange = (...args) => {
      setOption(args[0])
      return onChange?.(...args)
    }

    const handleFocus = (...args) => {
      setFocus(true)
      return onFocus?.(...args)
    }

    const handleBlur = (...args) => {
      setFocus(false)
      return onBlur?.(...args)
    }

    return (
      <div>
        <div className="relative">
          <ReactSelect
            classNamePrefix="select"
            components={props?.notRefresh ? null : { ClearIndicator }}
            styles={customStyles}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            isClearable
            {...props}
          />
          <Indicator input={option?.value} focus={focus} error={error} />
        </div>
        {error?.message && <Error message={error.message} />}
      </div>
    )
  }
)

/**
 * That's a controller for `react-hook-form`
 */
Select.Controller = ({ control, rules, ...props }) => {
  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={props.value || props.defaultValue}
      render={({ field }) => (
        <Select
          {...field}
          {...props}
          onChange={combineFn(props.onChange, field.onChange)}
        />
      )}
    />
  )
}

export default Select

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "16px",
    height: "42px",
    border: "0 !important",
    boxShadow: "0 !important",
    outline: state.isFocused && "2px solid var(--color-secondary)",
    paddingLeft: "32px",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    color: "#9ca3af",
  }),
}

function ClearIndicator(props) {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div
        title="Clear"
        className={`
          fill-primary
          cursor-pointer
          px-[5px]
        `}
      >
        <RefreshIcon className={`w-[16px] h-[16px]`} />
      </div>
    </div>
  )
}
