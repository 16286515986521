import readFileAsDataUrl from "@helpers/readFileAsDataUrl"
import { ReactComponent as CameraIcon } from "@icons/camera.svg"
import { ReactComponent as ProfileIcon } from "@icons/profile.svg"
import { forwardRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import Error from "./Error"

function Avatar({ className = "", error, onChange, defaultAvatar, ...props }, ref) {
  const [avatar, setAvatar] = useState(defaultAvatar)

  const handleChange = async (e) => {
    readFileAsDataUrl(e.target.files?.[0])
      .then(setAvatar)
      
    onChange?.(e)
  }

  return (
    <div className="text-center">
      <label 
        className={twMerge(`
          relative
          centered
          bg-light-primary 
          w-[160px] 
          h-[160px] 
          rounded-full 
          cursor-pointer`,
          className
        )} 
        style={{ 
          backgroundImage: avatar ? `url(${avatar})` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <ProfileIcon className="absolute-centered fill-primary" width="50%" height="50%" />
        <div
          className={twMerge(
            "rounded-full w-full h-full z-10 relative"
          )}
          style={{
            backgroundImage: avatar ? `url(${avatar})` : '',
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        />
        <Camera />
        <input
          ref={ref}
          type="file"
          className="w-[1px] h-[1px] opacity-0"
          {...props}
          onChange={handleChange}
        />
      </label>
      {error?.message && <Error message={error.message} />}
    </div>
  )
}

export default forwardRef(Avatar)

function Camera() {
  return (
    <div
      className={`
      absolute
      right-0
      bottom-0
      rounded-full 
      bg-white 
      w-[64px] 
      h-[64px] 
      centered
      shadow-md
      hover:bg-light-grey
      transition
      z-20
    `}
    >
      <CameraIcon className="fill-primary w-[32px] h-[32px]" />
    </div>
  )
}
