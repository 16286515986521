import TestController from "@api/TestController"
import Modal from "@blocks/Modal"
import PaginationTest from "@blocks/PaginationTest"
import Question from "@blocks/Question"
import Button from "@components/form/Button"
import Container from "@components/layout/Container"
import QuestionController from "@api/QuestionController"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import PageTemplate from "@templates/PageTemplate"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"

export default function TestPage() {
  useTitle("Test")
  useCategory("lessons")

  const { id } = useParams()
  const navigate = useNavigate()

  const started = useStartTest(id)
  const [answers, setAnswers] = useState({}) 
  const [errorText, setErrorText] = useState(false)
  const [questions, setQuestions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const [active, setActive] = useActiveQuestion(questions)
  useEffect(() => {
    if (started) {
      const getQuestions = async () => {
        setIsLoading(true)
        try {
          const res = await QuestionController.getQuestions({ lessonId: id })
          setQuestions(res)
        } catch (err) {
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getQuestions()
    }
  }, [started, id])

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  const handleSubmit = async (questionId, data) => {
    try {
      const answer = await TestController.submitAnswer({
        optionId: data.optionId,
      })
      setAnswers(old => ({ ...old, [questionId]: answer.data }))
    } catch (err) {
      toast.error(err.message)
    }
  }

  const handleFinishClick = async () => {
    await TestController.finishTest({ lessonId: id })
    navigate(`/lessons/${id}/results`)
  }

  const handleContinueClick = () => {
    setActive(old => old + 1)
  }

  if (isLoading || active === undefined) return null

  return (
    <Container.Medium className="h-full">
      <Modal
        isOpen={errorText}
        onRequestClose={() => {
          setErrorText("")
        }}
        shouldCloseOnOverlayClick={false}
        className="grid justify-items-center pt-[20px] w-[100%] max-w-[500px]"
      >
        <h2 className="text-center mt-[40px] mb-[20px]">{errorText}</h2>
        <Button
          type="button"
          onClick={() => {
            setErrorText("")
          }}
        >
          OK
        </Button>
      </Modal>
      <PageTemplate className="flex items-center flex-col gap-[48px]">
        <h2 className="text-center">{questions.data[active].question}</h2>
        <div>
          <PaginationTest
            total={questions.total}
            onClick={idx => setActive(idx)}
            active={active}
            setErrorText={setErrorText}
            questions={questions.data}
            answers={answers}
          />
        </div>
        <div>
          {questions?.data?.map((question, idx) => {
            return (
              <Question
                key={idx}
                visible={idx === active}
                idx={idx}
                question={question}
                answer={answers[question.id]}
                onSubmit={handleSubmit}
                onContinueClick={handleContinueClick}
                onFinishClick={handleFinishClick}
                isLast={idx === questions?.data?.length - 1}
              />
            )
          })}
        </div>
      </PageTemplate>
    </Container.Medium>
  )
}

function useStartTest(lessonId) {
  const [started, setStarted] = useState(false)

  useEffect(() => {
    TestController.startTest({ lessonId }).then(() => setStarted(true))
  }, [lessonId])

  return started
}

function useActiveQuestion(questions) {
  const [active, setActive] = useState()

  useEffect(() => {
    if (!questions?.data) return

    for (const [idx, question] of Object.entries(questions.data)) {
      if (question.attempted === false) {
        setActive(parseInt(idx))
        return
      }
    }

    setActive(questions?.data?.length - 1)
  }, [questions])

  return [active, setActive]
}
