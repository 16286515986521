import axios from "axios"

export default class SubjectController {
  static async getParentSubjects({ childMsisdn, classesId, length, start = 0 }) {
    const result = await axios.post('/user/parent/subject/list', { childMsisdn, classesId, length, start })
    return result.data
  }

  static async getSubjects({ length, start = 0 } = {}) {
    const result = await axios.post('/user/subject/list', { length, start })
    return result.data
  }
}