import when from "@helpers/when"
import { forwardRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import Error from "./Error"
import Indicator from "./Indicator"

function Input({ onChange, onFocus, onBlur, error, ...props }, ref) {
  const [input, setInput] = useState(props.defaultValue)
  const [focus, setFocus] = useState()

  const handleChange = (...args) => {
    setInput(args[0].target.value)
    return onChange?.(...args)
  }

  const handleFocus = (...args) => {
    setFocus(true)
    return onFocus?.(...args)
  }

  const handleBlur = (...args) => {
    setFocus(false)
    return onBlur?.(...args)
  }

  return (
    <div>
      <div className="relative">
        <input
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          {...props}
          className={twMerge(`
            w-full
            border-grey
            border-[1px]
            rounded-[16px]
            py-[8px]
            pr-[16px]
            pl-[42px]
            bg-[transparent]

            ${when(
              props.disabled,
              `
              bg-grey
              text-dark-grey
            `
            )}
          `)}
        />
        {props?.img ? props?.img : null}
        <Indicator {...{ focus, input, error, disabled: props.disabled }} />
      </div>
      {error?.message && <Error message={error.message} />}
    </div>
  )
}

export default forwardRef(Input)
