import { twMerge } from "tailwind-merge"

export default function Container({ type, children, className = "", ...props }) {
  switch (type) {
    case "fixed.xs":
      className = twMerge(`w-full m-auto px-[20px] max-w-[480px]`, className)
      break
    case "fixed.narrow":
      className = twMerge(`w-full m-auto px-[20px] max-w-[750px]`, className)
      break
    case "fixed.medium":
      className = twMerge(`w-full m-auto px-[20px] max-w-[900px]`, className)
      break
    case "fixed.wide":
      className = twMerge(`w-full m-auto px-[20px] max-w-[1200px]`, className)
      break
    default:
      className = twMerge(`w-full m-auto px-[20px] lg:px-[60px]`, className)
      break
  }

  return <div className={className} {...props}>{children}</div>
}

Container.XS = props => <Container type="fixed.xs" {...props} />
Container.Narrow = props => <Container type="fixed.narrow" {...props} />
Container.Medium = props => <Container type="fixed.medium" {...props} />
Container.Wide = props => <Container type="fixed.wide" {...props} />
