import { useEffect, useRef, useState } from "react"
import { ReactComponent as NotificationsIcon } from '@icons/notifications.svg'
import { ReactComponent as ArrowBottomIcon } from '@icons/arrow_bottom.svg'
import when from "@helpers/when"

export default function Notification({ title, time, children, onClick, ...props }) {
  const [visible, setVisible] = useState(props.visible || props.defaultVisible || false)

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  const handleClick = () => {
    const controlled = props.visible !== undefined

    if (!controlled)
      setVisible(old => !old)

    onClick?.()
  }

  return (
    <div className="py-[24px] border-b-[1px] border-b-grey">
      <div className="flex items-center cursor-pointer" onClick={handleClick}>
        <div className="mr-[18px] centered flex-shrink-0 bg-light-primary fill-primary w-[56px] h-[56px] rounded-[12px]"><NotificationsIcon /></div>
        <div className="w-full">
          <h3>{title}</h3>
          <p className="text-[12px] text-typography-grey">{time}</p>
        </div>
        <div className="flex-shrink-0 stroke-primary">
          <ArrowBottomIcon className={`
            transition 
            ${when(visible, `
              rotate-180
            `)}
          `} />
        </div>
      </div>
      <NotificationDescription visible={visible}>{children}</NotificationDescription>
    </div>
  )
}

function NotificationDescription({ visible, children }) {
  const ref = useRef()
  const [height, setHeight] = useState('0px')

  useEffect(() => {
    if (!visible) {
      setHeight('0px')
      return
    }

    const childrenHeight = Array.prototype.reduce.call(
      ref.current.childNodes, 
      (curr, prev) => curr + (prev.offsetHeight || 0), 
      0
    )

    setHeight(childrenHeight)
  }, [visible])

  return <div ref={ref} className={`
    font-light 
    leading-loose
    transition-[height]
    duration-300

    ${!visible ? "hidden": ""}
  `} style={{ height }}><p className="mt-[24px]">{children}</p></div>
}