export default function PageTemplate({ children, title, className }) {
  return (
    <div className="h-full flex flex-col justify-start relative">
      <div className="my-auto">
        <div className="py-[48px]">
          {title && (
            <h2 className="mx-auto mb-[32px] lg:mb-[64px] text-center">
              {title}
            </h2>
          )}
          <div className={className}>{children}</div>
        </div>
      </div>
    </div>
  )
}
