import React from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export default React.memo(Card)

function Card({ children, className = "", to, ...props }) {
  const attrs = {
    className: twMerge(`
      bg-white
      hover:shadow-lg
      transition
      rounded-[24px]
    `, className),
    ...props
  }

  const Wrapper = to
    ? () => <Link to={to} {...attrs}>{children}</Link>
    : () => <div {...attrs}>{children}</div>

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}
