import { useState } from "react"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import Modal from "@blocks/Modal"
import Pagination from "@blocks/Pagination"
import { ReactComponent as CloseIcon } from "@icons/close.svg"

import "./PdfPreviewModal.scss"

const PdfPreviewModal = ({ pdfFileLink, setPdfFileLink }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Modal
      isOpen={pdfFileLink ? true : false}
      onRequestClose={() => {
        setPdfFileLink(null)
      }}
      closeButtonHidden={true}
      className="w-[100%] h-[90%] bg-transparent max-w-[1000px] py-[0px]"
    >
      <div className="bg-white grid justify-items-center px-[20px] pb-[20px] pt-[40px] w-[100%] h-[100%] relative">
        <Close
          onClick={() => {
            setPdfFileLink(null)
          }}
        />
        <h2 className="mb-[20px]">File: {pdfFileLink?.fileName}</h2>
        <div className="overflow-y-auto overflow-x-hidden h-[90%] w-[100%]">
          <Document
            file={`data:application/pdf;base64,${pdfFileLink?.file}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
        <Pagination
          onPageChange={page => setPageNumber(page)}
          totalCount={numPages}
          siblingCount={1}
          currentPage={pageNumber}
          pageSize={1}
        />
      </div>
    </Modal>
  )
}

export default PdfPreviewModal

function Close({ onClick }) {
  return (
    <div
      className="absolute top-[20px] right-[20px] fill-black cursor-pointer"
      onClick={onClick}
    >
      {<CloseIcon />}
    </div>
  )
}
