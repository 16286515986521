import Avatar from "@components/form/Avatar"
import Button from "@components/form/Button"
import Datepicker from "@components/form/Datepicker"
import Error from "@components/form/Error"
import Input from "@components/form/Input"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import readFileAsDataUrl from "@helpers/readFileAsDataUrl"
import useCategory from "@hooks/useCategory"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import { mutateAvatar, updateAvatar, updateUser } from "@redux/user.slice"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

export default function ProfilePage() {
  const user = useSelector(state => state.user)
  const [error, setError] = useState()

  useTitle("Profile")
  useHeader({ className: "rounded-b-none" }, [])
  useCategory("profile")

  localStorage.removeItem("permanentUser")
  localStorage.removeItem("justLoggedIn")

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()

  const next = async data => {
    try {
      await dispatch(
        updateUser({
          role: user.role,
          birthday: data.birthday,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          parentsCode: user.parentsCode,
        })
      ).unwrap()

      toast.success("Profile updated!")
      setError()
    } catch (err) {
      setError(err.message)
    }

    if (data.avatar?.[0]) {
      readFileAsDataUrl(data.avatar[0]).then(async avatar => {
        try {
          await dispatch(updateAvatar(avatar)).unwrap()
          dispatch(mutateAvatar(avatar))
        } catch (err) {
          toast.error(`Can't update the avatar: ${err.message}`)
        }
      })
    }
  }

  return (
    <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
      <HeaderSubmenu
        className="relative h-[84px]"
        center={
          <div className="absolute z-10 left-1/2 top-0 -translate-x-1/2">
            <Avatar
              name="avatar"
              className="mx-auto"
              error={errors?.avatar}
              defaultAvatar={user.avatar}
              {...register("avatar")}
            />
          </div>
        }
      />
      <Container.XS className="h-full">
        <form
          className="h-full flex flex-col relative"
          onSubmit={handleSubmit(next)}
        >
          <div className="h-full overflow-auto mt-[96px] mb-[36px]">
            <div className="grid gap-[32px] h-full content-center px-[2px]">
              <div className="grid gap-[24px]">
                <div className="grid grid-cols-2 gap-[24px]">
                  <Input
                    placeholder="Name"
                    defaultValue={user.firstName}
                    error={errors?.firstName}
                    {...register("firstName", {
                      required: { message: "Name is required", value: true },
                      minLength: {
                        message: "Name's length must be more than 1 character.",
                        value: 2,
                      },
                      maxLength: {
                        message:
                          "Name's length must be less than 51 characters.",
                        value: 50,
                      },
                    })}
                  />
                  <Input
                    placeholder="Last name"
                    defaultValue={user.lastName}
                    error={errors?.lastName}
                    {...register("lastName", {
                      required: {
                        message: "Last name is required",
                        value: true,
                      },
                      minLength: {
                        message:
                          "Last name's length must be more than 1 character.",
                        value: 2,
                      },
                      maxLength: {
                        message:
                          "Last name's length must be less than 51 characters.",
                        value: 50,
                      },
                    })}
                  />
                </div>
                <div className="grid gap-[24px]">
                  <Datepicker.Controller
                    name="birthday"
                    placeholder="Date of birth"
                    isClearable
                    defaultValue={user.birthday}
                    error={errors?.birthday}
                    control={control}
                    notRefresh
                    rules={{
                      required: {
                        message: "Date of birth is required",
                        value: true,
                      },
                    }}
                  />
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email address"
                    error={errors?.email}
                    defaultValue={user.email}
                    {...register("email", {
                      required: {
                        message: "Email address is required",
                        value: true,
                      },
                    })}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col items-center">
                  <Button>Save</Button>
                  {error && <Error message={error} centered />}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container.XS>
    </div>
  )
}
