import VectorIconCard from "@blocks/VectorIconCard"
import Container from "@components/layout/Container"
import useCurricculumQuery from "@hooks/queries/useCurriculumQuery"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { useNavigate, Navigate } from "react-router-dom"
import { useState, useEffect } from "react"
import RegisterInfoModal from "@components/modals/RegisterInfoModal"

export default function CurriculumPage() {
  useTitle("Registration: Curriculum")

  const navigate = useNavigate()
  const permanentUser = JSON.parse(localStorage.getItem("permanentUser"))
  const { isLoading, data, error } = useCurricculumQuery()
  const justLoggedIn = JSON.parse(localStorage.getItem("justLoggedIn"))
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  useEffect(() => {
    if (justLoggedIn === true && permanentUser) {
      setIsInfoModalOpen(true)
    }
  }, [justLoggedIn, permanentUser])

  const next = curriculum => {
    localStorage.setItem(
      "permanentUser",
      JSON.stringify({ ...permanentUser, curriculum: curriculum, page: "/levels" })
    )
    navigate("/registration/levels")
  }

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      localStorage.setItem(
        "permanentUser",
        JSON.stringify({ ...permanentUser, subscription: false })
      )
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          localStorage.setItem(
            "permanentUser",
            JSON.stringify({ ...permanentUser, subscription: false })
          )
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (
    permanentUser?.role === "student" &&
    permanentUser?.subscription === true &&
    permanentUser?.page &&
    permanentUser?.page !== "/curriculum"
  ) {
    return <Navigate to={"/registration" + permanentUser?.page} />
  } else {
    return (
      <>
        <RegisterInfoModal
          permanentUser={permanentUser}
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={setIsInfoModalOpen}
        />
        <Container.Narrow className="h-full">
          <PageTemplate title="Choose your learning medium">
            <div className="grid grid-cols-3 gap-[32px]">
              {data.data.map(curriculum => (
                <VectorIconCard
                  key={curriculum.id}
                  icon={curriculum.icon}
                  onClick={() => next(curriculum)}
                >
                  {curriculum.name}
                </VectorIconCard>
              ))}
            </div>
          </PageTemplate>
        </Container.Narrow>
      </>
    )
  }
}
