import Modal from "@blocks/Modal"
import { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import ReactHlsPlayer from "react-hls-player"

export default function VideoModal({ request, ...props }) {
  const playerRef = useRef()
  const [src, setSrc] = useState(null)

  useEffect(() => {
    if (!request) return

    request.then(response => setSrc(response.data))

    return () => setSrc(null)
  }, [request])

  return (
    <Modal {...props} className={`w-[1200px]`}>
      {src && <ReactHlsPlayer playerRef={playerRef} width="100%" controls src={src} />}
    </Modal>
  )
}
