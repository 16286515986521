import Error from "@components/form/Error"
import combineFn from "@helpers/combineFn"
import range from "@helpers/range"
import useUpdateEffect from "@hooks/useUpdateEffect"
import { ReactComponent as StarIcon } from "@icons/star.svg"
import { forwardRef, useState } from "react"
import { Controller } from "react-hook-form"

const Stars = forwardRef(({ onChange, error }, ref) => {
  const [active, setActive] = useState()

  useUpdateEffect(() => {
    onChange?.(active + 1)
  }, [active])

  const handleClick = (idx) => {
    setActive(old => old === idx ? undefined : idx)
  }

  return (
    <div>
      <div className="flex gap-[6px]">
        {range(5).map(idx => <Star key={idx} idx={idx} active={idx <= active} onClick={() => handleClick(idx)} />)}
      </div>
      {error && <Error message={error.message} centered />}
    </div>
  )
})

Stars.Controller = forwardRef(({ control, rules, ...props }, ref) => {
  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={props.value || props.defaultValue}
      render={({ field }) => (
        <Stars
          {...field}
          {...props}
          onChange={combineFn(field.onChange, props.onChange)}
        />
      )}
    />
  )
})

export default Stars

function Star({ onClick, active }) {
  return (
    <div className="cursor-pointer">
      <StarIcon
        className={`
          transition
          ${active ? `fill-yellow` : `fill-transparent stroke-yellow`}
        `}
        strokeWidth="1.5"
        onClick={onClick}
      />
    </div>
  )
}