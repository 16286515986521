import combineFn from "@helpers/combineFn"
import range from "@helpers/range"
import when from "@helpers/when"
import { forwardRef, useRef } from "react"
import { Controller } from "react-hook-form"
import { twMerge } from "tailwind-merge"
import Error from "./Error"

const InputOtp = forwardRef(
  ({ onChange, error, centered, length = 6, ...props }, ref) => {
    const { containerRef, handleChange, handleInput, handleKeyUp } = useOtp({
      onChange,
    })

    return (
      <div ref={ref}>
        <div
          className={twMerge(`
        flex 
        gap-[8px]
        
        ${when(centered, `justify-center`)}
      `)}
          ref={containerRef}
        >
          {range(length).map(idx => (
            <input
              key={idx}
              className={`
              bg-transparent 
              border-secondary 
              border
              rounded-[8px]
              w-[40px]
              h-[50px]
              text-[30px]
              font-semibold
              text-center
            `}
              autoFocus={idx === 0 && props.autoFocus}
              onChange={handleChange}
              onInput={handleInput(idx)}
              onKeyUp={handleKeyUp(idx)}
              onFocus={e => e.target.select()}
              defaultValue={props.defaultValue?.[idx] || ""}
              maxLength="1"
            />
          ))}
        </div>
        {error?.message && (
          <Error message={error.message} centered={centered} />
        )}
      </div>
    )
  }
)

InputOtp.Controller = ({ control, rules, ...props }) => {
  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <InputOtp
          {...props}
          {...field}
          onChange={combineFn(props.onChange, field.onChange)}
        />
      )}
    />
  )
}

export default InputOtp

function useOtp({ onChange }) {
  const containerRef = useRef()

  const handleChange = () => onChange?.(mergeOtp(containerRef.current))

  const handleInput = idx => {
    return e =>
      changeFocus(containerRef, findDirectionByValue(e.target.value), idx)
  }

  const handleKeyUp = idx => {
    return e => {
      if (["Delete", "Backspace"].includes(e.key) && e.target.value === "") {
        changeFocus(containerRef, findDirectionByValue(e.target.value), idx)
      }

      if (e.key === "ArrowRight") {
        changeFocus(containerRef, "next", idx)
      }

      if (e.key === "ArrowLeft") {
        changeFocus(containerRef, "prev", idx)
      }
    }
  }

  return {
    containerRef,
    handleChange,
    handleInput,
    handleKeyUp,
  }
}

function changeFocus(ref, direction, idx) {
  const next = idx + 2
  const prev = idx

  if (direction === "next") {
    ref.current.querySelector(`input:nth-child(${next})`)?.focus()
  } else {
    ref.current.querySelector(`input:nth-child(${prev})`)?.focus()
  }
}

function findDirectionByValue(value) {
  return value !== undefined && value !== "" ? "next" : "prev"
}

function mergeOtp(container) {
  if (!container) return
  const inputs = Array.from(container.querySelectorAll("input"))
  const result = inputs.map(input => input.value)
  return result.join("")
}
