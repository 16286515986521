import Button from "@components/form/Button"
import when from "@helpers/when"
import React from "react"
import { Link } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import Card from "./Card"

export default React.memo(ErrorCard)

function ErrorCard({
  title = "Error",
  to = "/",
  button = "Got it",
  href,
  children,
  onClick,
  className,
  ...props
}) {
  const LinkWrapper =
    when(to, Link) ||
    when(href, ({ children, ...props }) => <a {...props}>{children}</a>)
  return (
    <Card
      {...props}
      className={twMerge("p-[24px] hover:shadow-none", className)}
    >
      <div className="mb-[16px]">
        <h2 className="font-bold">{title}</h2>
        <p>
          <small className="text-dark-grey">{children}</small>
        </p>
      </div>
      {onClick ? (
        <Button type="button" onClick={onClick}>
          {button}
        </Button>
      ) : (
        <LinkWrapper to={to} href={href}>
          <Button type="button">{button}</Button>
        </LinkWrapper>
      )}
    </Card>
  )
}
