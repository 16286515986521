import React from 'react'
import GuestMiddleware from "@middleware/GuestMiddleware"
import { Route, Routes } from "react-router-dom"
import PrivacyPolicyPage from './page/PrivacyPolicy'

export default function PrivacyPolicyroutes() {
  return (
    <Routes>
    <Route element={<GuestMiddleware />}>
      <Route index element={<PrivacyPolicyPage />} />
    </Route>
  </Routes>
  )
}
