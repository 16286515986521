import { twMerge } from "tailwind-merge";
import { ReactComponent as PDFIcon } from "@icons/pdf.svg"

export default function ButtonPdf({ className, ...props }) {
  return (
    <button 
      type="button" 
      className={twMerge(`
        rounded-full 
        centered 
        bg-primary 
        fill-white 
        h-[42px] 
        w-[42px] 
        hover:shadow-md 
        transition
      `, className)} 
      {...props}
    >
      <PDFIcon />
    </button>
  )
}
