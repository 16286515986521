import React from "react"

export default React.memo(SummaryCard)

function SummaryCard({ label, value }) {
  return (
    <div className="rounded-[10px] p-[24px] bg-light-grey text-center">
      <p className="text-secondary text-[22px]">{value}</p>
      <p className="text-typography-grey text-[12px]">{label}</p>
    </div>
  )
}
