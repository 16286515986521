import React from "react"
import Card from "./Card"

export default React.memo(ResultsCard)

function ResultsCard({ score, label, color, className }) {
  return (
    <Card
      className={`w-[190px] h-[190px] p-[24px] hover:shadow-none ${className}`}
    >
      <p className="text-[72px] leading-none" style={{ color }}>
        {score}
      </p>
      <p className="font-normal text-dark-grey mt-[8px]">{label}</p>
    </Card>
  )
}
