import TestController from "@api/TestController"
import { useQuery } from "react-query"

export default function useSummaryQuery(subjects) {
  return useQuery(
    `summary`,
    () => {
      const overall = TestController.getOverallSummary()
        .then(addLabel("All"))

      const summaries = subjects.data.map(subject =>
        TestController.getSummary({
          subjectId: subject.id,
        }).then(addLabel(subject.name))
      )

      return Promise.all([overall, ...summaries])
    },
    { enabled: Boolean(subjects?.data) }
  )
}

function addLabel(label) {
  return response => {
    response.data.label = label
    return response.data
  }
}