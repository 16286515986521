import { twMerge } from "tailwind-merge";
import { ReactComponent as PlayIcon } from "@icons/play.svg"

export default function ButtonPlay({ className, ...props }) {
  return (
    <button
      type="button"
      className={twMerge(`
        rounded-full 
        centered 
        bg-light-grey 
        fill-primary 
        h-[42px] 
        w-[42px] 
        hover:bg-white 
        hover:shadow-md 
        transition
      `, className)}
      {...props}
    >
      <PlayIcon />
    </button>
  )
}
