import Toasts from "@blocks/Toasts"
import Footer from "@components/layout/Footer"
import Header from "@components/layout/Header"
import when from "@helpers/when"
import { ReactComponent as HomeIcon } from "@icons/home.svg"
import { ReactComponent as LessonsIcon } from "@icons/lessons.svg"
import { ReactComponent as LibraryIcon } from "@icons/library.svg"
import { ReactComponent as NotificationsIcon } from "@icons/notifications.svg"
import { ReactComponent as SummaryIcon } from "@icons/summary.svg"
import { useSelector } from "react-redux"
import { Outlet, useLocation } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import "./MainTemplate.scss"

export default function MainTemplate() {
  const title = useSelector(state => state.app.title)
  const header = useSelector(state => state.header)
  const category = useSelector(state => state.app.category)
  const navigation = useMainTemplateNavigation(category)

  return (
    <div className="bg-light-grey min-h-screen">
      <div className="flex-shrink-0 absolute w-full z-20">
        <Header title={title} category={category} {...header} />
      </div>
      <div
        className={twMerge(`
        ${
          category === "home"
            ? window.location.pathname === `${process.env.PUBLIC_URL}/`
              ? "h-screen-without-header"
              : "h-screen-without-header-2"
            : "h-screen"
        }
        w-[100vw]
        pt-[96px] 
        pb-[85px] 
        z-10

        ${when(
          !Boolean(navigation?.length),
          `
          pb-0
        `
        )}
      `)}
      >
        <div className="h-full overflow-auto">
          <Outlet />
        </div>
      </div>
      <Toasts />
      {Boolean(navigation?.length) && (
        <div className="flex-shrink-0 absolute w-full bottom-0 z-20">
          <Footer navigation={navigation} />
        </div>
      )}
    </div>
  )
}

function useMainTemplateNavigation(category) {
  const location = useLocation()

  if (category === "registration") return

  if (location.pathname.startsWith("/parent")) {
    return [
      {
        icon: <SummaryIcon />,
        active: category === "summary",
        label: "Summary",
        to: "/parent",
      },
    ]
  }

  return [
    { icon: <HomeIcon />, active: category === "home", label: "Home", to: "/" },
    {
      icon: <LessonsIcon />,
      active: category === "lessons",
      label: "Lessons",
      to: "/lessons",
    },
    {
      icon: <LibraryIcon />,
      active: category === "library",
      label: "Library",
      to: "/library",
    },
    {
      icon: <NotificationsIcon />,
      active: category === "notifications",
      label: "Notifications",
      to: "/notifications",
    },
    {
      icon: <SummaryIcon />,
      active: category === "summary",
      label: "Summary",
      to: "/summary",
    },
  ]
}
