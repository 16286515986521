import { useState, useEffect } from "react"
import PreviousYearExamController from "@api/PreviousYearExamController"
import ExamCard from "@blocks/ExamCard"
import { useModal } from "@blocks/Modal"
import Container from "@components/layout/Container"
import VideoModal from "@components/modals/VideoModal"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { useParams } from "react-router-dom"
import PdfPreviewModal from "@blocks/PdfPreviewModal"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"

export default function ExamsPage() {
  const { year, id } = useParams() /* 
  const { data: exams, error, isLoading } = useExamsQuery(id, year) */
  const videoModal = useModal()

  useTitle(`Exams > ${year}`)
  useCategory("home")
  const navigate = useNavigate()

  const [pdfFileLink, setPdfFileLink] = useState(null)

  const [isLoading, setIsLoading] = useState(true)
  const [exams, setExams] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (id && year) {
      const getChapters = async () => {
        setIsLoading(true)
        try {
          const res = await PreviousYearExamController.getPreviousYearExams({
            subjectId: id,
            year: year,
          })
          setExams(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getChapters()
    }
  }, [id, year])

  const onPreviewPdf = async exam => {
    const res = await PreviousYearExamController.getPreviousYearExamFile({
      uuid: exam.uuid,
    })
    setPdfFileLink(res.data)
  }

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (!exams?.data?.length) {
    return (
      <ErrorPageTemplate title="There're no exams here">
        We haven't uploaded exams for this year yet. Please, come back later.
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.Narrow className="h-full">
      <PageTemplate>
        <VideoModal {...videoModal.props} />
        <div className="grid grid-cols-2 gap-[16px] items-start">
          {exams?.data?.map((exam, idx) => (
            <ExamCard
              key={exam.uuid}
              hasFile={exam.hasFile}
              hasVideo={exam.hasVideo}
              onFileClick={() => onPreviewPdf(exam)}
              onVideoClick={() =>
                videoModal.open({
                  request: PreviousYearExamController.getPreviousYearExamVideo({
                    uuid: exam.uuid,
                  }),
                })
              }
            >
              Exam Number {idx + 1}
            </ExamCard>
          ))}
        </div>
      </PageTemplate>
      <PdfPreviewModal
        pdfFileLink={pdfFileLink}
        setPdfFileLink={setPdfFileLink}
      />
    </Container.Narrow>
  )
}
