import ProfileController from "@api/ProfileController"
import SignInController from "@api/SignInController"
import role from "@constants/role"
import formatDateIfExists from "@helpers/formatDateIfExists"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = null

const fetchUser = createAsyncThunk(
  'user/fetch',
  async () => {
    let promises = []
    let user = await ProfileController.getStudentProfile()
      .then(response => response.data)

    user.role = role.student

    if (!user.parentsCode) {
      user = await ProfileController.getParentProfile()
        .then(response => response.data)

      user.role = role.parent

      promises = user.children.map(
        (child, idx) => ProfileController.getChildAvatar(child.msisdn)
          .then(avatar => user.children[idx].avatar = avatar)
      )
    }

    promises.push(
      ProfileController.getAvatar()
        .then(avatar => user.avatar = avatar)
    )

    await Promise.allSettled(promises)

    return user
  }
)

const logoutUser = createAsyncThunk(
  'user/logout',
  async () => SignInController.logout()
)

const saveUser = createAsyncThunk(
  'user/store',
  ({ role, ...data }) => ProfileController.saveProfile(role, data)
)

const updateUser = createAsyncThunk(
  'user/update',
  ({ role, ...data }) => ProfileController.updateProfile(role, data)
)

const updateAvatar = createAsyncThunk(
  'user/avatar/update',
  (avatar) => ProfileController.updateAvatar(avatar)
)

const saveChild = createAsyncThunk(
  'user/children/store',
  (parentsCode) => ProfileController.saveChild(parentsCode)
)

export const userSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    mutateSubscription(state, action) {
      return { ...state, subscription: action.payload }
    },
    mutateRole(state, action) {
      state.role = action.payload
    },
    mutateCode(state, action) {
      state.parentsCode = action.payload
    },
    mutateAvatar(state, action) {
      state.avatar = action.payload
    },
    mutate(state, action) {
      formatDateIfExists(action.payload, 'birthday')
      return { ...state, ...action.payload }
    },
    clearUser() {
      return null
    },
    mutateCurriculum(state, action) {
      state.curriculum = action.payload
    },
    mutateLevel(state, action) {
      state.level = action.payload
    },
    mutateClass(state, action) {
      state.class = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      return action.payload
    })

    builder.addCase(fetchUser.rejected, (state, action) => {
      if (action.error.message === "Subscription is not active") {
        return { record: false, subscription: false }
      }
      if (action.error.message === "Record not found")
        return { record: false, subscription: true }

      return null
    })

    builder.addCase(updateUser.fulfilled, (state, action) => {
      formatDateIfExists(action.meta.arg, 'birthday')
      return { ...state, ...action.meta.arg }
    })

    builder.addCase(logoutUser.fulfilled, (state, action) => {
      return null
    })
  },
})

export { fetchUser, logoutUser, saveUser, updateAvatar, updateUser, saveChild }

export const { mutateRole, mutateCode, mutate, mutateCurriculum, mutateLevel, mutateClass, mutateAvatar, clearUser, mutateSubscription } = userSlice.actions

export default userSlice.reducer
