import { ReactComponent as ProfileIcon } from "@icons/profile.svg"
import { twMerge } from "tailwind-merge"

export default function Avatar({
  avatar,
  foregroundClassName,
  backgroundClassName,
  size = "50px"
}) {
  return (
    <div
      className={twMerge(
        "relative bg-white rounded-full",
        backgroundClassName
      )}
      style={{
        width: size,
        height: size
      }}
    >
      <ProfileIcon className="absolute-centered fill-primary" width="50%" height="50%" />
      <div
        className={twMerge(
          "rounded-full w-full h-full z-10 relative",
          foregroundClassName
        )}
        style={{
          backgroundImage: avatar ? `url(${avatar})` : '',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}
      />
    </div>
  )
}
