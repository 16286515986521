import React from "react"
import GuestMiddleware from "@middleware/GuestMiddleware"
import { Route, Routes } from "react-router-dom"
import ContactUsPage from "./pages/ContactUs"

export default function ContactUsroutes() {
  return (
    <Routes>
      <Route element={<GuestMiddleware />}>
        <Route index element={<ContactUsPage />} />
      </Route>
    </Routes>
  )
}
