import LessonCard from "@blocks/LessonCard"
import Container from "@components/layout/Container"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import { useState, useEffect } from "react"
import TestController from "@api/TestController"
import withIcons from "@helpers/withIcons"

export default function LessonsPage() {
  useTitle("My Lessons")
  useCategory("lessons")
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [lessons, setLessons] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const getLessons = async () => {
      setIsLoading(true)
      try {
        const res = await withIcons(TestController.getTests(), "lessonUuid")
        setLessons(res)
      } catch (err) {
        console.log("error message", err?.message)
        setError(err?.message)
      }
      setIsLoading(false)
    }
    getLessons()
  }, [])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (!lessons?.data?.length) {
    return (
      <ErrorPageTemplate title="You haven't started any tests">
        You should start a test to see data here
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.Medium className="h-full">
      <PageTemplate title="Select a lesson to get started">
        <div className="grid grid-cols-2 gap-[24px]">
          {lessons?.data?.map(lesson => (
            <LessonCard
              to={`/lessons/${lesson.lessonId}`}
              illustration={lesson.icon}
              title={lesson.name}
              description={lesson.subtitle}
              status={lesson.status}
              rating={lesson.rating}
              level={lesson.level}
              subject={lesson.subject}
            />
          ))}
        </div>
      </PageTemplate>
    </Container.Medium>
  )
}
