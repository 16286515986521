import axios from "axios"

export default class LessonController {
  static async getLessons({ subjectId, chapterId, length, start = 0 }) {
    const result = await axios.post("/user/lesson/list", {
      length,
      start,
      subjectId,
      chapterId,
    })
    return result.data
  }

  static async getLesson({ lessonId }) {
    const result = await axios.post("/user/lesson/get", { lessonId })
    return result.data
  }

  static async getLessonFile({ uuid }) {
    const result = await axios.get(`/user/lesson/file/get/${uuid}`)
    return result.data
  }

  static async rateLesson({ lessonId, rate, text }) {
    const result = await axios.post(`/user/lesson/rate`, {
      lessonId,
      rate,
      text,
    })
    return result.data
  }

  static async getLessonVideoStreamUrl({ uuid }) {
    const result = await axios.get(`/user/lesson/video/stream/url/get/${uuid}`)
    return result.data
  }

  static async getFeedbacks({ start = 0, length, lessonId }) {
    const result = await axios.post(`/user/lesson/feedback/list`, {
      start,
      length,
      lessonId,
    })
    return result.data
  }
}
