import { SpinnerCircular } from "spinners-react";

export default function CornerLoading({ visible }) {
  if (!visible) return null
  
  return (
    <div
      title="We're fetching the data right now"
      className={`
        absolute 
        right-[24px]
        bottom-[24px]
        z-50
        transition
        flex
        items-center
      `}
    >
      <p className="mr-[8px]"><small className="text-dark-grey">Loading...</small></p>
      <SpinnerCircular
        size={36}
        thickness={100}
        speed={100}
        color="var(--color-primary)"
        secondaryColor="rgba(0, 0, 0, 0.1)"
      />
    </div>
  )
}
