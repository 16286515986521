import YearCard from "@blocks/YearCard"
import Container from "@components/layout/Container"
import titlify from "@helpers/titlify"
import useSubjectByIdQuery from "@hooks/queries/useSubjectByIdQuery"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import PreviousYearExamController from "@api/PreviousYearExamController"
import { useState, useEffect } from "react"

export default function PreviousYearsPage() {
  const { id } = useParams()
  const { data: subject } = useSubjectByIdQuery(id)
  const [isLoading, setIsLoading] = useState(true)
  const [years, setYears] = useState(null)
  const [error, setError] = useState(null)

  useTitle(
    titlify(subject?.name && `${subject.name} > Previous Years`, isLoading)
  )
  useCategory("home")
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      const getYears = async () => {
        setIsLoading(true)
        try {
          const res = await PreviousYearExamController.getPreviousYears({
            subjectId: id,
          })
          setYears(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getYears()
    }
  }, [id])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (!years?.data?.length) {
    return (
      <ErrorPageTemplate title="No previous exams for this subject">
        We can't find previous exams for the subject. Please, return to the main
        page.
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.Narrow className="h-full">
      <PageTemplate>
        <div className="grid grid-cols-2 gap-[16px] items-start">
          {years?.data?.map(year => (
            <YearCard key={year} to={`/subjects/${id}/years/${year}`}>
              {year}
            </YearCard>
          ))}
        </div>
      </PageTemplate>
    </Container.Narrow>
  )
}
