import Avatar from "@blocks/Avatar"
import SummaryStatistics from "@blocks/SummaryStatistics"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import formatName from "@helpers/formatName"
import useCategory from "@hooks/useCategory"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import PageTemplate from "@templates/PageTemplate"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import ClassController from "@api/ClassControlle"
import { useEffect } from "react"
import TestController from "@api/TestController"
import SubjectController from "@api/SubjectController"

export default function SummaryChildPage() {
  const { uuid } = useParams()
  const user = useSelector(state => state.user)
  const child = useChild(user, uuid)
  const dropdown = useDropdown(user, child)
  const [childClass, setChildClass] = useState({
    className: child?.className,
    classId: child?.classId,
  })
  const [subjects, setSubjects] = useState([])
  const [statistics, setStatistics] = useState([])

  localStorage.removeItem("permanentUser")
  localStorage.removeItem("justLoggedIn")

  useTitle(`${child?.firstName} ${child?.lastName}`)
  useCategory("summary")
  useHeader(
    {
      className: "rounded-b-none",
      dropdown,
    },
    []
  )

  const category = useSelector(state => state.app.category)

  const { data } = useQuery(`classes-${child?.levelId}`, () =>
    ClassController.getClasses({ levelId: child?.levelId })
  )

  useEffect(() => {
    const getSubjects = () => {
      const subjects = SubjectController.getParentSubjects({
        childMsisdn: child.msisdn,
        classesId: childClass?.classId ? childClass?.classId : child.classId,
      })

      return subjects
    }
    const getSubjectsSecondPart = async () => {
      const res = await getSubjects()
      setSubjects(res)
    }
    getSubjectsSecondPart()
  }, [child, childClass])

  useEffect(() => {
    const getParentSummary = () => {
      const overall = TestController.getParentOverallSummary({
        childMsisdn: child.msisdn,
        classesId: childClass?.classId ? childClass?.classId : child.classId,
      }).then(addLabel("All"))

      const summaries = subjects?.data?.map(subject =>
        TestController.getParentSummary({
          childMsisdn: child.msisdn,
          classesId: childClass?.classId ? childClass?.classId : child.classId,
          subjectId: subject.id,
        }).then(addLabel(subject.name))
      )

      if (summaries) {
        return Promise.all([overall, ...summaries])
      } else {
        return Promise.all([overall])
      }
    }
    const getParentSummarySecondPart = async () => {
      const res = await getParentSummary()
      setStatistics(res)
    }
    getParentSummarySecondPart()
  }, [childClass, subjects, child])

  if (statistics?.length < 1 || subjects?.length < 1 || child == false)
    return null

  return (
    <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
      <HeaderSubmenu
        className="relative h-[84px]"
        left={
          <div className="flex text-white fill-white gap-[36px]">
            <div className="flex items-center gap-[8px]">
              <div className="rounded-full bg-white opacity-20 w-[16px] h-[16px]" />
              <p className="max-w-[250px] overflow-hidden whitespace-nowrap text-ellipsis">
                {child.levelName}
              </p>
            </div>
            <div className="flex items-center gap-[8px]">
              <div className="rounded-full bg-white opacity-20 w-[16px] h-[16px]" />
              <p className="max-w-[250px] overflow-hidden whitespace-nowrap text-ellipsis">
                {childClass?.className
                  ? childClass?.className
                  : child.className}
              </p>
            </div>
          </div>
        }
        center={
          <div className="absolute z-10 left-1/2 top-[1px] -translate-x-1/2">
            <Avatar size="160px" avatar={child.avatar} />
          </div>
        }
      />
      <Container.Medium className="h-full pt-[48px]">
        <PageTemplate>
          <SummaryStatistics
            statistics={statistics}
            data={data}
            childClass={childClass}
            setChildClass={setChildClass}
            classDropdownShown={
              category === "summary" && user.role === "parent" ? true : false
            }
          />
        </PageTemplate>
      </Container.Medium>
    </div>
  )
}

function useDropdown(user, child) {
  const navigate = useNavigate()

  const options = [{ value: 0, label: "Summary" }].concat(
    user.children.map(child => ({
      value: child.uuid,
      label: formatName(child),
    }))
  )

  const handleChange = item => {
    if (item.value === 0) {
      navigate("/parent")
    } else {
      navigate(`/parent/children/${item.value}/summary`)
    }
  }

  return {
    options,
    value: options.find(option => option.value === child.uuid),
    onChange: handleChange,
  }
}

function useChild(user, uuid) {
  return useMemo(
    () => user.children.find(child => child.uuid === uuid),
    [user, uuid]
  )
}

function addLabel(label) {
  return response => {
    response.data.label = label
    return response.data
  }
}
