import ButtonPdf from "@components/form/ButtonPdf"
import ButtonPlay from "@components/form/ButtonPlay"
import React from "react"
import Card from "./Card"

export default React.memo(ExamCard)

function ExamCard({ children, onFileClick, hasFile, hasVideo, onVideoClick }) {
  if (!hasFile && !hasVideo) return null

  return (
    <Card className={"rounded-[16px]"}>
      <div className="flex py-[24px] px-[24px] justify-between items-center">
        <span>{children}</span>
        <div className="flex gap-[12px]">
          {hasFile && <ButtonPdf onClick={onFileClick} />}
          {hasVideo && <ButtonPlay onClick={onVideoClick} />}
        </div>
      </div>
    </Card>
  )
}
