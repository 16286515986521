import { useState, useEffect } from "react"
import BookLibraryController from "@api/BookLibraryController"
import BookCard from "@blocks/BookCard"
import Container from "@components/layout/Container"
import pluralize from "@helpers/pluralize"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import PdfPreviewModal from "@blocks/PdfPreviewModal"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"

export default function LibraryPage() {
  useTitle("Library")
  useCategory("library")
  const navigate = useNavigate()

  const [pdfFileLink, setPdfFileLink] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [books, setBooks] = useState(null)
  const [error, setError] = useState(null)
  const [total, setTotal] = useState(1)

  useEffect(() => {
    const getBooks = async () => {
      setIsLoading(true)
      try {
        const res = await BookLibraryController.getBooks({
          name: null,
          length: total,
          start: 0,
        })
        setBooks(res)
        setTotal(res.total)
      } catch (err) {
        console.log("error message", err?.message)
        setError(err?.message)
      }
      setIsLoading(false)
    }
    getBooks()
  }, [total])

  const onPreviewPdf = async book => {
    const res = await BookLibraryController.getBook({ uuid: book.uuid })
    setPdfFileLink(res.data)
  }

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (!books?.data?.length) {
    return (
      <ErrorPageTemplate title="No books in the library">
        We haven't uploaded any books yet. Please, come back later.
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.XS className="h-full">
      <PageTemplate title="Select a book">
        <div className="grid gap-[24px] items-start">
          {books?.data?.map(book => (
            <BookCard
              key={book.id}
              title={book.name}
              description={`${book.pages} ${pluralize("page", book.pages)} (${
                book.size
              })`}
              onDownloadClick={() => onPreviewPdf(book)}
            />
          ))}
        </div>
      </PageTemplate>
      <PdfPreviewModal
        pdfFileLink={pdfFileLink}
        setPdfFileLink={setPdfFileLink}
      />
    </Container.XS>
  )
}
