import when from "@helpers/when";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function NavButton({ className, active, ...props }) {
  const Wrapper = props.to
    ? Link
    : (props) => <button {...props}>{props.children}</button>

  return (
    <Wrapper {...props} className={twMerge(`
      w-[56px] 
      h-[56px] 
      bg-white/[.1]
      rounded-[20px]
      centered
      stroke-white
      fill-white
      cursor-pointer
      hover:bg-white/[.3]
      transition

      ${when(active, `
        bg-white/[.3]
      `)}
    `, className)}>
      {props.icon}
    </Wrapper>
  )
}
