import "@boot/axios"
import root from "@boot/root"
import { store } from "@redux/store"
import React from "react"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { Provider } from "react-redux"
import App from "./App"
import "react-toastify/dist/ReactToastify.css"
import "react-dropdown/style.css"
import "./index.scss"
import ErrorBoundary from "@blocks/ErrorBoundary"

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
})

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
)
