import ChapterCard from "@blocks/ChapterCard"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import pluralize from "@helpers/pluralize"
import ChapterController from "@api/ChapterController"
import withIcons from "@helpers/withIcons"
import useSubjectByIdQuery from "@hooks/queries/useSubjectByIdQuery"
import useCategory from "@hooks/useCategory"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import { ReactComponent as RefreshIcon } from "@icons/refresh.svg"
import PageTemplate from "@templates/PageTemplate"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import { Link, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import { useState, useEffect } from "react"

export default function ChaptersPage() {
  const { id } = useParams()
  const { data: subject } = useSubjectByIdQuery(id)

  useTitle(subject?.name || "Loading...")
  useCategory("home")
  useHeader({ className: "rounded-b-none" }, [])
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [chapters, setChapters] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (id) {
      const getChapters = async () => {
        setIsLoading(true)
        try {
          const res = await withIcons(
            ChapterController.getChapters({ subjectId: id })
          )
          setChapters(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getChapters()
    }
  }, [id])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  return (
    <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
      <HeaderSubmenu
        left={
          <div className="flex text-white fill-white">
            <p className="mr-[36px]">
              {chapters?.total} {pluralize("Chapter", chapters?.total)}
            </p>
            <p className="flex items-center">
              <span className="mr-[8px]">
                <RefreshIcon />
              </span>
              <Link to={`/subjects/${id}/years`}>Previous Years</Link>
            </p>
          </div>
        }
      />
      <Container.Medium className="h-full">
        <PageTemplate title="What would you like to learn today?">
          <div className="grid grid-cols-2 gap-[24px] items-start">
            {chapters?.data?.map((chapter, idx) => (
              <ChapterCard
                key={chapter.id}
                to={`/subjects/${id}/chapters/${chapter.id}/lessons`}
                illustration={chapter.icon}
                title={chapter.name}
                description={`Chapter ${idx + 1}`}
              />
            ))}
          </div>
        </PageTemplate>
      </Container.Medium>
    </div>
  )
}
