
import { getResData } from "./getResData";
import axios from "axios"

export const getEvcPricePoints = async () => {
  try {
    const res = await axios.get("/user/price/point/evc");
    return getResData(res.data);
  } catch (error) {
    console.error("getEvcPricePoints error => ", error);
    console.log("Error Response getEvcPricePoints => ", error.response);
    return null;
  }
};

export const getAirtimePricePoints = async () => {
  try {
    const res = await axios.get("/user/aoc/ordinary");
    return getResData(res.data);
  } catch (error) {
    console.error("getAirtimePricePoints error => ", error);
    console.log("Error Response getAirtimePricePoints => ", error.response);
    return null;
  }
};

export const subscribeEvcUser = async (values) => {
  try {
    const res = await axios.post("/user/subscribe/evc", values);
    return getResData(res.data);
  } catch (error) {
    console.error("subscribeUser error => ", error);
    console.log("subscribeUser => ", error.response);
    return error;
  }
};

export const subscribeAirtimeUser = async () => {
  try {
    const res = await axios.post("/user/subscribe/ordinary");
    return getResData(res.data);
  } catch (error) {
    console.error("subscribeUser error => ", error);
    console.log("subscribeUser => ", error.message);
    return error;
  }
};

export const unsubscribeUser = async () => {
  try {
    const res = await axios.post("/user/unsubscribe");
    return getResData(res.data);
  } catch (error) {
    console.error("unsubscribeUser error => ", error);
    console.log("unsubscribeUser => ", error.message);
    return error;
  }
};