import readFileAsDataUrl from "@helpers/readFileAsDataUrl";
import axios from "axios";

export default class ProfileController {
  static async isStudent() {
    const result = await axios.get('/user/profile/student/exist')
    return result.data
  }

  static async getStudentProfile() {
    const result = await axios.get('/user/profile/student/get')
    return result.data
  }

  static async getParentProfile() {
    const result = await axios.get('/user/profile/parent/get')
    return result.data
  }

  static async saveProfile(role, data) {
    const result = await axios.post(`/user/profile/${role}/save`, data)
    return result.data
  }

  static async updateProfile(role, data) {
    const result = await axios.post(`/user/profile/${role}/update`, data)
    return result.data
  }

  static async updateAvatar(avatar) {
    const result = await axios.post(`/user/profile/avatar/update`, { avatar: avatar.split(',')[1] })
    
    return result.data
  }

  static async getAvatar() {
    const result = await axios.get('/user/profile/avatar/get', {
      responseType: 'blob'
    })

    return await readFileAsDataUrl(result.data)
  }

  static async getChildAvatar(childMsisdn) {
    const result = await axios.get('/user/profile/child/avatar/get', { 
      params: { childMsisdn },
      responseType: 'blob'
    })

    return await readFileAsDataUrl(result.data)
  }

  static async saveChild(parentsCode) {
    const result = await axios.post('/user/profile/parent/child/save', {
      parentsCode
    })

    return result.data
  }
}