import Avatar from "@blocks/Avatar"
import SummaryStatistics from "@blocks/SummaryStatistics"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import useSummaryQuery from "@hooks/queries/useSummaryQuery"
import useCategory from "@hooks/useCategory"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import PageTemplate from "@templates/PageTemplate"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import SubjectController from "@api/SubjectController"
import { useState, useEffect } from "react"

export default function SummaryPage() {
  const user = useSelector(state => state.user)
  const [subjects, setSubjects] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const { data: statistics } = useSummaryQuery(subjects)

  useTitle(`${user.firstName} ${user.lastName}`)
  useCategory("summary")
  useHeader({ className: "rounded-b-none" }, [])
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      const getSubjects = async () => {
        setIsLoading(true)
        try {
          const res = await SubjectController.getSubjects()
          setSubjects(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getSubjects()
    }
  }, [user])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  return (
    <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
      <HeaderSubmenu
        className="relative h-[84px]"
        left={
          <div className="flex text-white fill-white gap-[36px]">
            <div className="flex items-center gap-[8px]">
              <div className="rounded-full bg-white opacity-20 w-[16px] h-[16px]" />
              <p className="max-w-[250px] overflow-hidden whitespace-nowrap text-ellipsis">
                {user.levelName}
              </p>
            </div>
            <div className="flex items-center gap-[8px]">
              <div className="rounded-full bg-white opacity-20 w-[16px] h-[16px]" />
              <p className="max-w-[250px] overflow-hidden whitespace-nowrap text-ellipsis">
                {user.className}
              </p>
            </div>
          </div>
        }
        center={
          <div className="absolute z-10 left-1/2 top-[1px] -translate-x-1/2">
            <Avatar size="160px" avatar={user.avatar} />
          </div>
        }
      />
      <Container.Medium className="h-full pt-[48px]">
        <PageTemplate>
          <SummaryStatistics statistics={statistics} />
        </PageTemplate>
      </Container.Medium>
    </div>
  )
}
