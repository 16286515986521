import PageTemplate from "@templates/PageTemplate"
import { useEffect } from "react"
import { useState, useCallback } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import {
  getAirtimePricePoints,
  getEvcPricePoints,
  subscribeEvcUser,
  subscribeAirtimeUser,
} from "../services"
import InputPhone from "@components/form/InputPhone"
import Modal from "@blocks/Modal"
import { fetchUser, mutateSubscription } from "@redux/user.slice"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import Header from "@components/layout/Header"
import RegisterInfoModal from "@components/modals/RegisterInfoModal"
import ProfileController from "@api/ProfileController"
import { useRef } from "react"

const SubscriptionOption = ({ label, selected, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex mb-[12px] text-[14px] select-none items-center"
    >
      <span
        className={`h-[20px] w-[20px] rounded-[50%] mr-[10px] ${
          selected ? "bg-primary" : "bg-grey"
        }`}
      />
      <span className={`${selected ? "text-primary" : "text-grey"}`}>
        {label}
      </span>
    </button>
  )
}

const SubscriptionPage = () => {
  const [paymentMethod, setPaymentMethod] = useState("evc")
  const [evcPriceList, setEvcPriceList] = useState()
  const [airtimePriceList, setAirtimePriceList] = useState()
  const [isModalOpen, setIsModalOpen] = useState({ values: null, open: false })
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [updateUser, setUpdateUser] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [numberError, setNumberError] = useState("")
  const [evcLabel, setEvcLabel] = useState(
    evcPriceList ? `${evcPriceList[0].title} for ${evcPriceList[0].price}$` : ""
  )
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const permanentUser = JSON.parse(localStorage.getItem("permanentUser"))
  const justLoggedIn = JSON.parse(localStorage.getItem("justLoggedIn"))

  const phoneRegExp = /(\+252)\d{9,10}$/

  /* const [profileExist, setProfileExist] = useState(false) */
  const profileExist = useRef()

  useEffect(() => {
    const checkHasProfile = async () => {
      const res = await ProfileController.isStudent()
      profileExist.current = res?.data
    }
    checkHasProfile()
  }, [])

  const subscriptionValSchema = Yup.object().shape({
    evcNumber:
      paymentMethod === "evc"
        ? Yup.string()
            .min(12, "Invalid phone number")
            .max(13, "Invalid phone number")
            .matches(phoneRegExp, "Invalid phone number")
        : Yup.string().nullable(),
    pricePointId:
      paymentMethod === "evc"
        ? Yup.number().required("Price is required")
        : Yup.string().nullable(),
  })

  const onConfirmModalSubscription = async values => {
    setDisabled(true)
    const res =
      paymentMethod === "evc"
        ? await subscribeEvcUser(values)
        : await subscribeAirtimeUser()
    if (res && res?.data) {
      dispatch(mutateSubscription(true))
      setUpdateUser(!updateUser)
      if (permanentUser?.role) {
        localStorage.setItem(
          "permanentUser",
          JSON.stringify({ ...permanentUser, subscription: true })
        )
      }
      const result = await dispatch(fetchUser())
      setNumberError("")
      if (result?.error?.message === "Subscription is not active") {
        setTimeout(async () => {
          await onConfirmModalSubscription(values)
        }, 5000)
      }
    } else if (
      res?.message === "Internal server error: Subscription already exists"
    ) {
      console.log("error was true")
      setUpdateUser(!updateUser)
      setNumberError(res.message)
      await dispatch(fetchUser())
      await dispatch(fetchUser())
    } else {
      setNumberError(res.message)
    }
  }
  const getEvcPricePointsList = useCallback(async () => {
    const res = await getEvcPricePoints()

    if (res && res.result) {
      setEvcPriceList(res.data)
    }
  }, [])

  const getAirtimePricePointsList = useCallback(async () => {
    const res = await getAirtimePricePoints()

    if (res && res.result) {
      setAirtimePriceList(res.data)
    }
  }, [])

  useEffect(() => {
    !evcPriceList && getEvcPricePointsList()
    !airtimePriceList && getAirtimePricePointsList()
  }, [
    evcPriceList,
    airtimePriceList,
    getEvcPricePointsList,
    getAirtimePricePointsList,
  ])

  useEffect(() => {
    if (justLoggedIn === true && permanentUser) {
      setIsInfoModalOpen(true)
    }
  }, [justLoggedIn, permanentUser])

  if (!user && !permanentUser && profileExist.current === false) {
    return <Navigate to="/login" />
  }
  if (user?.subscription === false && profileExist.current === false) {
    if (
      user?.record === false &&
      !permanentUser?.role &&
      profileExist.current === false
    ) {
      return <Navigate to="/registration" />
    }
  }
  if (user?.subscription !== false) {
    if (user?.record === false && permanentUser?.role === "student") {
      localStorage.setItem(
        "permanentUser",
        JSON.stringify({ ...permanentUser, subscription: true })
      )
      return <Navigate to="/registration/profile" />
    } else if (user?.role) {
      if (user?.role === "student") {
        return <Navigate to="/" />
      }
      if (user?.role === "parent") {
        return <Navigate to="/parent" />
      }
    }
  }

  const days = airtimePriceList?.hours / 24
  const airtimeLabel = `Elearning will cost ${airtimePriceList?.price}$ per ${days} days or ${airtimePriceList?.minutes} minutes of Anfac`

  if (!airtimePriceList || !evcPriceList) return

  return (
    <>
      <RegisterInfoModal
        permanentUser={permanentUser}
        isInfoModalOpen={isInfoModalOpen}
        setIsInfoModalOpen={setIsInfoModalOpen}
      />
      <Header title="Subscription" category="subscription" />
      <div className="w-[100%] grid justify-center items-center mt-[80px]">
        <PageTemplate title="Select payment method">
          <Formik
            initialValues={{
              evcNumber: "",
              pricePointId: evcPriceList && evcPriceList[0]?.pricePointId,
            }}
            validationSchema={subscriptionValSchema}
            onSubmit={values => {
              setIsModalOpen({ values: values, open: true })
            }}
          >
            {({ values, setFieldValue, errors, touched, handleSubmit }) => {
              return (
                <Form
                  className="grid justify-items-center mt-[90px]"
                  onSubmit={handleSubmit}
                >
                  <div className="grid grid-cols-2 gap-[30px]">
                    <button
                      type="button"
                      className={`rounded-[24px] shadow-2xl py-[25px] px-[105px] text-primary ${
                        paymentMethod === "airtime" ? "bg-light-primary" : ""
                      }`}
                      onClick={() => {
                        setPaymentMethod("airtime")
                        setFieldValue(
                          "pricePointId",
                          airtimePriceList[0]?.pricePointId
                        )
                        setFieldValue("evcNumber", "")
                        setEvcLabel("")
                      }}
                    >
                      Airtime
                    </button>
                    <button
                      type="button"
                      className={`rounded-[24px] shadow-2xl py-[25px] px-[105px] text-primary ${
                        paymentMethod === "evc" ? "bg-light-primary" : ""
                      }`}
                      onClick={() => {
                        setPaymentMethod("evc")
                        setFieldValue(
                          "pricePointId",
                          evcPriceList[0]?.pricePointId
                        )
                        setEvcLabel(
                          `${evcPriceList[0].title} for ${evcPriceList[0].price}$`
                        )
                      }}
                    >
                      EVC
                    </button>
                  </div>
                  {paymentMethod === "evc" && (
                    <div className="mt-[40px]">
                      <p className="text-center text-[18px] leading-[27px]">
                        Enter EVC number
                      </p>
                      <InputPhone
                        placeholder="Phone"
                        defaultValue={"+252"}
                        error={errors.evcNumber}
                        onChange={event => {
                          setFieldValue("evcNumber", event.target.value)
                        }}
                        autoFocus
                      />
                      {errors.evcNumber && (
                        <p className="text-red">{errors.evcNumber}</p>
                      )}
                    </div>
                  )}

                  <div className="mt-[40px] w-auto">
                    {paymentMethod === "evc" &&
                      evcPriceList?.map(priceItem => {
                        const label = `${priceItem.title} for ${priceItem.price}$`
                        return (
                          <SubscriptionOption
                            key={priceItem.id}
                            label={label}
                            selected={
                              values.pricePointId === priceItem.pricePointId
                            }
                            onClick={() => {
                              setFieldValue(
                                "pricePointId",
                                priceItem.pricePointId
                              )
                              setEvcLabel(label)
                            }}
                          />
                        )
                      })}
                  </div>
                  <button
                    type="submit"
                    className="bg-primary py-[16px] px-[114px] rounded-[16px] mt-[90px] text-white"
                  >
                    Pay
                  </button>
                </Form>
              )
            }}
          </Formik>
          <Modal
            isOpen={isModalOpen.open}
            onRequestClose={() => {
              setIsModalOpen({ values: null, open: false })
              setDisabled(false)
              setNumberError("")
            }}
            className="w-[100%] bg-white max-w-[540px] py-[0px] rounded-[10px] px-[28px]"
          >
            <h2 className="text-[24px] leading-[36px] font-bold text-center pt-[138px]">
              Payment Confirmation
            </h2>
            <p className="text-dark-grey-2 text-[14px] leading-[21px] text-center">
              Are you sure you want to make a payment for this package?
            </p>
            <p className="text-primary text-[16px] leading-[21px] text-center mt-[40px]">
              {paymentMethod === "evc" ? evcLabel : airtimeLabel}
            </p>
            <div className="flex justify-around mt-[80px] pb-[20px]">
              <button
                type="button"
                onClick={() => {
                  setIsModalOpen({ values: null, open: false })
                  setDisabled(false)
                  setNumberError("")
                }}
                className="border-[1px] border-red-2 bg-white rounded-[100px] py-[12px] w-[164px] text-red-2"
              >
                Cancel
              </button>
              <button
                disabled={disabled}
                type="button"
                onClick={() => {
                  onConfirmModalSubscription(isModalOpen.values)
                }}
                className="border-[1px] border-primary disabled:border-dark-grey bg-primary disabled:bg-dark-grey rounded-[100px] py-[12px] w-[164px] text-white"
              >
                Confirm
              </button>
            </div>
            <p className="text-red px-[28px] pb-[55px] text-center">
              {numberError}
            </p>
          </Modal>
        </PageTemplate>
      </div>
    </>
  )
}

export default SubscriptionPage
