import role from "@constants/role"
import UnexpectedRoleError from "@errors/UnexpectedRoleError"
import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

export default function ParentMiddleware() {
  const user = useSelector(state => state.user)

  if (!user) return <Navigate to="/login" />

  if (user?.record === false) return <Navigate to="/registration" />
  localStorage.removeItem("permanentUser")
  switch (user?.role) {
    case role.student:
      return <Navigate to="/" />
    case role.parent:
      return <Outlet />
    default:
      throw new UnexpectedRoleError(user?.role)
  }
}
