import Container from "@components/layout/Container"
import VectorIllustrationCard from "@blocks/VectorIllustrationCard"
import useTitle from "@hooks/useTitle"
import { ReactComponent as StudentIllustration } from "@illustrations/student.svg"
import { ReactComponent as ParentIllustration } from "@illustrations/parent.svg"
import PageTemplate from "@templates/PageTemplate"
import { Navigate, useNavigate } from "react-router-dom"
import role from "@constants/role"
import { useEffect, useRef, useState } from "react"
import ProfileController from "@api/ProfileController"
import axios from "axios"

export default function RolesPage() {
  useTitle("Roles")

  const navigate = useNavigate()
  const permanentUser = JSON.parse(localStorage.getItem("permanentUser"))
  const [profileExist, setProfileExist] = useState()

  const checkHasProfile = async () => {
    const res = await axios.get("/user/profile/student/exist")
    setProfileExist(res?.data?.data)
  }

  useEffect(() => {
    if (profileExist === undefined) {
      checkHasProfile()
    }
  }, [profileExist])

  const handleClick = role => {
    localStorage.setItem(
      "permanentUser",
      JSON.stringify({ ...permanentUser, role: role })
    )

    switch (role) {
      case "parent":
        localStorage.removeItem("justLoggedIn")
        navigate("/registration/code")
        break
      case "student":
      default:
        localStorage.setItem(
          "permanentUser",
          JSON.stringify({ ...permanentUser, role: role, page: "/profile" })
        )

        localStorage.removeItem("justLoggedIn")
        if (permanentUser?.role === "student" && !permanentUser?.subscription) {
          navigate("/subscription")
        } else {
          navigate("/registration/profile")
        }
        break
    }
  }

  if (permanentUser?.role) {
    if (permanentUser?.role === "student" && !permanentUser?.subscription) {
      localStorage.setItem(
        "permanentUser",
        JSON.stringify({
          ...permanentUser,
          role: "student",
          page: permanentUser?.page ? permanentUser?.page : "/profile",
        })
      )
      return <Navigate to="/subscription" />
    } else {
      switch (permanentUser?.role) {
        case "parent":
          return <Navigate to="/registration/code" />
        case "student":
        default:
          localStorage.setItem(
            "permanentUser",
            JSON.stringify({
              ...permanentUser,
              role: "student",
              page: permanentUser?.page ? permanentUser?.page : "/profile",
            })
          )
          return <Navigate to="/registration/profile" />
      }
    }
  } else if (profileExist === true) {
    return <Navigate to="/subscription" />
  } else if (profileExist === false) {
    return (
      <Container.Narrow className="h-full">
        <PageTemplate title="Choose your suitable role">
          <div className="grid grid-cols-2 gap-[32px]">
            <VectorIllustrationCard
              illustration={<StudentIllustration />}
              onClick={() => handleClick(role.student)}
            >
              I’m a student
            </VectorIllustrationCard>
            <VectorIllustrationCard
              illustration={<ParentIllustration />}
              onClick={() => handleClick(role.parent)}
            >
              I’m a parent
            </VectorIllustrationCard>
          </div>
        </PageTemplate>
      </Container.Narrow>
    )
  }
}
