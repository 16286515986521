import React, { useEffect, useState } from "react"
import FooterLogo from "../../icons/Logo.svg"
import MailIcon from "../../icons/Icon.svg"
import PhoneIcon from "../../icons/phoneIcon.svg"
import LocationIcon from "../../icons/LocationIcon.svg"
import twitterIcon from "../../icons/twitter.svg"
import facebookIcon from "../../icons/FaceBookIcon.svg"
import LinkedInIcon from "../../icons/LinkedIN.svg"
import { getPrivacyPolicy, getTermsAndConditions } from "@login/services"
import Modal from "@blocks/Modal"
import { ReactComponent as CloseIcon } from "@icons/close.svg"

export default function NewFooter() {

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)
  const [privacyPolicy, setprivacyPolicy] = useState(null)
  const [clicked, setClicked] = useState(false)
  const [termsError, setTermsError] = useState("")
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [termsAndConditions, setTermsAndConditions] = useState(null)


  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      const res = await getPrivacyPolicy()
      if (res && res.data) {
        setprivacyPolicy(res.data.replace(/\n/g, "<br />"))
      } else {
        setprivacyPolicy("Unexpected error observed Try again later")
      }
    }
    fetchPrivacyPolicy()
  }, [])

  
  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      const res = await getTermsAndConditions()
      if (res && res.data) {
        setTermsAndConditions(res.data.replace(/\n/g, "<br />"))
      } else {
        setTermsAndConditions("Something went wrong")
      }
    }
    fetchTermsAndConditions()
  }, [])

  useEffect(() => {
    if (clicked) {
      setTermsError("")
    }
  }, [clicked])
  return (
    <footer>
      <div className="py-4 px-20 pt-[20px] pb-[60px]">
      <div className="flex flex-col gap-[30px]">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-[14px]">
            <div className="mb-[30px]">
              <img src={FooterLogo} alt="Logo" />
            </div>
            <div className="flex gap-[4px]">
              <img src={MailIcon} alt="Mail Icon Button"/>
              <p>info@mocalim.so</p>
            </div>
            <div className="flex gap-[4px]">
              <img src={PhoneIcon} alt="Phone Icon Button"/>
              <p>+252770900004</p>
            </div>
            <div className="flex gap-[4px]">
              <img src={LocationIcon} alt="Location icon" />
              <p>Mogadishu, Somalia</p>
            </div>
          </div>
          {/* Other Sided */}

          <div className="flex flex-row gap-20">
            <div className="flex flex-col place-items-start gap-[8px]">
              <div>
                <p className=" font-semibold text-[18px]">Home</p>
              </div>
              <button className="text-[16px] text-[#59595A]">Benefits</button>
              <button className="text-[16px] text-[#59595A]">Our Courses</button>
              <button className="text-[16px] text-[#59595A]">Our Testimonials</button>
              <button className="text-[16px] text-[#59595A]">Our FAQ</button>
            </div>
            <div className="flex flex-col place-items-start gap-[8px]">
              <div>
                <p className="text-[18px]">About Us</p>
              </div>
              <button className="text-[16px] text-[#59595A]" >company</button>
              <button className="text-[16px] text-[#59595A]" >Achievements</button>
              <button className="text-[16px] text-[#59595A]" >Our Goals</button>
            </div>
            <div className="flex flex-col gap-[14px] text-[16px]">
              <div>
                <p className="text-[18px]">Social Profiles</p>
              </div>
              <div className="flex flex-row gap-[14px] ">
                <button className=""><img src={facebookIcon} alt="FaceBook Icon " width={20} height={20} /></button>
                <button><img src={twitterIcon} alt="twitter Icon" /></button>
                <button><img src={LinkedInIcon} alt="LinkedIn Icon" /></button>
              </div>
            </div>
          </div>
        </div>
        <hr className=" border-[#F1F1F3]"></hr>
        <div className="flex flex-row justify-between">
          <div className="text-[14px] text-[#656567]">© 2024 Innovii. All rights reserved.</div>
          <div className="flex gap-5">
          <button  onClick={() => setIsPrivacyPolicyOpen(true)} className="text-[14px] text-[#656567]" >Privacy Policy</button>
          <button onClick={() => setIsTermsModalOpen(true)} className="text-[14px] text-[#656567]">Terms of Service</button>
          </div>
        </div>

       
      </div>
      </div>
      <Modal
        isOpen={isTermsModalOpen}
        onRequestClose={() => {
          setIsTermsModalOpen(false)
        }}
        closeButtonHidden={true}
        className="w-[100%] bg-transparent max-w-[1000px] py-[0px]"
      >
        <div className="bg-white grid justify-items-center px-[20px] pb-[20px] pt-[40px] w-[100%] h-[100%] relative">
          <Close
            onClick={() => {
              setIsTermsModalOpen(false)
            }}
          />
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
              className="text-[16px]"
            ></p>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={isPrivacyPolicyOpen}
        onRequestClose={() => {
          setIsPrivacyPolicyOpen(false)
        }}
        closeButtonHidden={true}
        className="w-[100%] bg-transparent max-w-[1000px] py-[0px]"
      >
        <div className="bg-white grid justify-items-center rounded-2xl px-[20px] pb-[20px] pt-[40px] w-[100%] h-[100%] relative">
          <Close
            onClick={() => {
              setIsPrivacyPolicyOpen(false)
            }}
          />
          <div className="overflow-auto  max-h-[42rem]">
            <p
              dangerouslySetInnerHTML={{ __html: privacyPolicy }}
              className="text-[16px] overflow-auto"
            ></p>
          </div>
        </div>
      </Modal>
    </footer>
  )
}

function Close({ onClick }) {
  return (
    <div
      className="absolute top-[20px] right-[20px] fill-black cursor-pointer"
      onClick={onClick}
    >
      {<CloseIcon />}
    </div>
  )
}
