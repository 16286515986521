import readFileAsDataUrl from "@helpers/readFileAsDataUrl"
import axios from "axios"

export default class FileResourceController {
  static async getBgImage(uuid) {
    const result = await axios.get(`/user/resource/bg-image/get/${uuid}`, {
      responseType: 'blob'
    })

    return await readFileAsDataUrl(result.data)
  }

  static async getIcon(uuid) {
    const result = await axios.get(`/user/resource/icon/get/${uuid}`, {
      responseType: 'blob'
    })

    return await readFileAsDataUrl(result.data)
  }
}