import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  title: undefined,
  category: undefined,
  loading: 0
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setCategory: (state, action) => {
      state.category = action.payload
    },
    loadingStart: (state, payload) => {
      state.loading++
    },
    loadingFinish: (state, payload) => {
      state.loading--
    }
  },
})

export const { setTitle, setCategory, loadingStart, loadingFinish } = appSlice.actions

export default appSlice.reducer
