import axios from "axios"

export default class PreviousYearExamController {
  static async getPreviousYears({ subjectId, length, start = 0 }) {
    const result = await axios.post('/user/previous/year/list', { length, start, subjectId })
    return result.data
  }

  static async getPreviousYearExams({ length, start = 0, subjectId, year }) {
    const result = await axios.post('/user/previous/year/exam/list', { length, start, subjectId, year })
    return result.data
  }

  static async getPreviousYearExamFile({ uuid }) {
    const result = await axios.get(`/user/previous/year/exam/file/get/${uuid}`)
    return result.data
  }
  static async getPreviousYearExamVideo({ uuid }) {
    const result = await axios.get(`/user/previous/year/exam/video/stream/url/get/${uuid}`)
    return result.data
  }
}