import StudentMiddleware from "@middleware/StudentMiddleware"
import MainTemplate from "@templates/MainTemplate"
import { Route, Routes } from "react-router-dom"
import ChapterLessonsPage from "./pages/ChapterLessonsPage"
import ChaptersPage from "./pages/ChaptersPage"
import DashboardPage from "./pages/DashboardPage"
import ExamsPage from "./pages/ExamsPage"
import LessonPage from "./pages/LessonPage"
import LessonsPage from "./pages/LessonsPage"
import LibraryPage from "./pages/LibraryPage"
import NotificationsPage from "./pages/NotificationsPage"
import PreviousYearsPage from "./pages/PreviousYearsPage"
import ProfilePage from "./pages/ProfilePage"
import RatePage from "./pages/RatePage"
import SubjectsPage from "./pages/SubjectsPage"
import SummaryPage from "./pages/SummaryPage"
import TestPage from "./pages/TestPage"
import TestResultsPage from "./pages/TestResultsPage"

export default function StudentRoutes() {
  return (
    <Routes>
      <Route element={<StudentMiddleware />}>
        <Route element={<MainTemplate />}>
          <Route index element={<SubjectsPage />} />
          <Route path="subjects/:id/chapters" element={<ChaptersPage />} />
          <Route path="subjects/:subjectId/chapters/:chapterId/lessons" element={<ChapterLessonsPage />} />
          <Route path="subjects/:id/years" element={<PreviousYearsPage />} />
          <Route path="subjects/:id/years/:year" element={<ExamsPage />} />
          <Route path="lessons" element={<LessonsPage />} />
          <Route path="lessons/:id" element={<LessonPage />} />
          <Route path="lessons/:id/test" element={<TestPage />} />
          <Route path="lessons/:id/results" element={<TestResultsPage />} />
          <Route path="lessons/:id/rate" element={<RatePage />} />
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="library" element={<LibraryPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="summary" element={<SummaryPage />} />
          <Route path="profile" element={<ProfilePage />} />
        </Route>
      </Route>
    </Routes>
  )
}
