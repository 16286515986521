import FileResourceController from "@api/FileResourceController"
import ignore from "./ignore"

export default async function withIcons(promise, uuid = "uuid") {
  const response = await promise

  if (response.data) {
    const promises = response.data.map((item, idx) => {
      return FileResourceController.getIcon(item[uuid])
        .then(icon => { response.data[idx].icon = icon })
    })
  
    await Promise.allSettled(promises)
  } else {
    response.icon = await FileResourceController.getIcon(response[uuid])
      .catch(ignore)
  }

  return response
}