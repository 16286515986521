import Modal from "@blocks/Modal"

const RegisterInfoModal = ({
  permanentUser,
  isInfoModalOpen,
  setIsInfoModalOpen,
}) => {
  return (
    <Modal
      isOpen={isInfoModalOpen}
      onRequestClose={() => {
        localStorage.removeItem("justLoggedIn")
        setIsInfoModalOpen(false)
      }}
      className="w-[100%] bg-white max-w-[540px] py-[0px] rounded-[10px] px-[28px]"
    >
      <p className="text-[18px] leading-[21px] text-center mt-[40px] mb-[20px]">
        Do you want to continue with data:
      </p>{" "}
      {permanentUser?.role ? (
        <p>
          <span className="text-primary">role: &nbsp;</span>{" "}
          {permanentUser?.role}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.parentsCode ? (
        <p>
          <span className="text-primary">parents code: &nbsp;</span>{" "}
          {permanentUser?.parentsCode}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.firstName ? (
        <p>
          <span className="text-primary">first name: &nbsp;</span>{" "}
          {permanentUser?.firstName}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.lastName ? (
        <p>
          <span className="text-primary">last name: &nbsp;</span>{" "}
          {permanentUser?.lastName}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.birthday ? (
        <p>
          <span className="text-primary">birthday: &nbsp;</span>{" "}
          {permanentUser?.birthday}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.email ? (
        <p>
          <span className="text-primary">email: &nbsp;</span>{" "}
          {permanentUser?.email}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.referralCode ? (
        <p>
          <span className="text-primary">referral code: &nbsp;</span>{" "}
          {permanentUser?.referralCode}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.curriculum ? (
        <p>
          <span className="text-primary">curriculum: &nbsp;</span>{" "}
          {permanentUser?.curriculum?.name}
        </p>
      ) : (
        ""
      )}
      {permanentUser?.level ? (
        <p>
          <span className="text-primary">level: &nbsp;</span>{" "}
          {permanentUser?.level?.name}
        </p>
      ) : (
        ""
      )}
      <div className="flex justify-around mt-[80px] pb-[20px]">
        <button
          type="button"
          onClick={() => {
            localStorage.removeItem("justLoggedIn")
            localStorage.removeItem("permanentUser")
            setIsInfoModalOpen(false)
          }}
          className="border-[1px] border-red-2 bg-white rounded-[100px] py-[12px] w-[164px] text-red-2"
        >
          No
        </button>
        <button
          type="button"
          onClick={() => {
            localStorage.removeItem("justLoggedIn")
            setIsInfoModalOpen(false)
          }}
          className="border-[1px] border-primary disabled:border-dark-grey bg-primary disabled:bg-dark-grey rounded-[100px] py-[12px] w-[164px] text-white"
        >
          Yes
        </button>
      </div>
    </Modal>
  )
}

export default RegisterInfoModal
