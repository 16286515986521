import { ReactComponent as ArrowRightIcon } from "@icons/arrow_right.svg"
import React from "react"
import Card from "./Card"

export default React.memo(ChapterCard)

function ChapterCard({ illustration, description, title, to }) {
  return (
    <Card
      to={to}
      className={`
        group
        bg-transparent
        hover:bg-white
      `}
    >
      <div
        className={`
          relative
          py-[16px]
          px-[20px] 
          min-h-[140px]
          flex
          items-stretch
        `}
      >
        <div
          className="bg-grey w-[132px] rounded-[10px] flex-shrink-0 mr-[24px] group-hover:bg-light-primary transition"
          style={{
            backgroundImage: `url(${illustration})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div>
          <div className="h-full flex flex-col justify-center relative transition">
            <h3>{title}</h3>
            {description && (
              <p>
                <small>{description}</small>
              </p>
            )}
          </div>
        </div>
        <Arrow />
      </div>
    </Card>
  )
}

function Arrow() {
  return (
    <div
      className={`
      absolute
      opacity-0
      w-[24px]
      h-[24px]
      bg-primary
      rounded-full
      centered
      stroke-white
      group-hover:opacity-100
      group-hover:-translate-y-[24px] 
      transition
      bottom-[0]
      left-[28px]
    `}
    >
      <ArrowRightIcon />
    </div>
  )
}
