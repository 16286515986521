import InputOtp from "@components/form/InputOtp"
import SignInController from "@api/SignInController"
import { fetchUser } from "@redux/user.slice"
import UnauthenticatedTemplate from "@templates/UnauthenticatedTemplate"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import token from "@constants/token"

export default function OtpPage() {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const loginConfirm = async data => {
    try {
      const response = await SignInController.loginConfirm({
        msisdn: location.state.msisdn,
        code: data.otp,
      })
      localStorage.setItem(token, response.data)
      localStorage.setItem("justLoggedIn", true)
      await dispatch(fetchUser())
    } catch (err) {
      setError(
        "otp",
        { type: "server", message: err.message },
        { shouldFocus: true }
      )
      if (err?.message?.includes("Account is temporary blocked")) {
        setTimeout(() => navigate("/login"), 5000)
      }
    }
  }

  const resend = async () => {
    navigate("/login", { state: { msisdn: location.state.msisdn } })
  }

  return (
    <UnauthenticatedTemplate
      onSubmit={handleSubmit(loginConfirm)}
      title="OTP"
      description={`Please, type a verification code sent to ${location.state.msisdn}`}
    >
      <div className="grid gap-[16px]">
        <InputOtp.Controller
          centered
          name="otp"
          control={control}
          error={errors.otp}
          autoFocus
          rules={{
            required: {
              message: "The code is required",
              value: true,
            },
            pattern: {
              message: "The code should consist of 6 digits",
              value: /^[0-9]{6}$/,
            },
          }}
        />
        <button
          type="button"
          className="text-primary font-normal"
          onClick={resend}
        >
          Resend
        </button>
      </div>
    </UnauthenticatedTemplate>
  )
}
