import when from "@helpers/when";
import { twMerge } from "tailwind-merge";

export default function Button({ children, className, secondary, ...props }) {
  return (
    <button
      className={twMerge(`
        bg-primary
        rounded-[16px]
        text-white
        w-[250px]
        h-[54px]
        hover:darker
        transition
      `, 
      when(secondary, `
        bg-transparent
        border-2
        border-primary
        text-primary
        hover:darker-none
        hover:opacity-70
      `), 
      className)}
      {...props}
    >
      {children}
    </button>
  )
}
