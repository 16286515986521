import date from "@constants/date"
import { ReactComponent as CalendarIcon } from "@icons/calendar.svg"
import { ReactComponent as RefreshIcon } from "@icons/refresh.svg"
import { forwardRef, useState } from "react"
import ReactDatepicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"
import Error from "./Error"
import Indicator from "./Indicator"

const Datepicker = forwardRef(
  (
    {
      onChange,
      onFocus,
      onBlur,
      onClear,
      isClearable,
      placeholder,
      error,
      ...props
    },
    ref
  ) => {
    const [input, setInput] = useState(getDefaultValue(props.defaultValue))
    const [focus, setFocus] = useState(false)

    const handleChange = (...args) => {
      setInput(args[0] || undefined)
      setFocus(false)
      return onChange?.(args[0])
    }

    const handleFocus = (...args) => {
      setFocus(true)
      return onFocus?.(...args)
    }

    const handleBlur = (...args) => {
      setFocus(false)
      return onBlur?.(...args)
    }

    const handleClear = (...args) => {
      setInput()
      setFocus(false)
      onChange?.()
      return onClear?.(...args)
    }

    return (
      <div>
        <div className="relative">
          <ReactDatepicker
            ref={ref}
            selected={input}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholderText={placeholder}
            dateFormat={date.format}
            {...props}
            className={`
            w-full
            border-grey
            border-0
            rounded-[16px]
            py-[8px]
            pr-[16px]
            pl-[42px]
          `}
          />
          <Indicator input={input} focus={focus} error={error} />
          <Icon input={input} />
          {props.notRefresh ? null : (
            <ClearIndicator
              input={input}
              enabled={isClearable}
              onClick={handleClear}
            />
          )}
        </div>
        {error?.message && <Error message={error.message} />}
      </div>
    )
  }
)

/**
 * That's a controller for `react-hook-form`
 */
Datepicker.Controller = forwardRef(({ control, rules, ...props }, ref) => {
  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      ref={ref}
      defaultValue={props.defaultValue}
      render={({ field }) => <Datepicker {...props} {...field} />}
    />
  )
})

export default Datepicker

function ClearIndicator({ input, enabled, onClick }) {
  if (!enabled) return null

  if (!input) return null

  return (
    <div
      title="Clear"
      className={`
    absolute 
    right-[18px] 
    top-1/2 
    -translate-y-1/2
    fill-primary
    cursor-pointer
  `}
      onClick={onClick}
    >
      <RefreshIcon className={`w-[16px] h-[16px]`} />
    </div>
  )
}

function Icon({ input }) {
  if (input) return null

  return (
    <div
      className={`
    absolute 
    right-[18px] 
    top-1/2 
    -translate-y-1/2
    fill-primary
  `}
    >
      <CalendarIcon className={`w-[16px] h-[16px]`} />
    </div>
  )
}

function getDefaultValue(value) {
  if (!value) return

  return value instanceof Date ? value : new Date(value)
}
