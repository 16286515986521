import LessonController from "@api/LessonController"
import withIcons from "@helpers/withIcons"
import { useQuery } from "react-query"

export default function useLessonByIdQuery(id) {
  return useQuery(`lesson-${id}`, () =>
    withIcons(
      LessonController.getLesson({ lessonId: id }).then(
        response => response.data
      )
    )
  )
}
