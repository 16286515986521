import token from "@constants/token"
import CustomServerError from "@errors/CustomServerError"
import { loadingFinish, loadingStart } from "@redux/app.slice"
import { store } from "@redux/store"
import { clearUser } from "@redux/user.slice"
import axios from "axios"

axios.interceptors.request.use(function (config) {
  store.dispatch(loadingStart())

  config.baseURL =
    window.globalConfig.serverUrl || process.env.REACT_APP_SERVER_URL
  config.headers.Authorization = localStorage[token]
    ? `Bearer ${localStorage[token]}`
    : ""

  return config
})

axios.interceptors.response.use(
  function (response) {
    store.dispatch(loadingFinish())

    if (notAuthorized(response)) {
      localStorage.removeItem("token")
      store.dispatch(clearUser())
    } else if (response.data.result === "error") {
      throw new CustomServerError(
        response.data.errorDesc,
        response.data.debugInfo
      )
    }

    return response
  },
  function (err) {
    store.dispatch(loadingFinish())
    return Promise.reject(err)
  }
)

function notAuthorized(response) {
  return response.data.errorDesc === "User not authorized"
}
