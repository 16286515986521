import ClassController from "@api/ClassControlle"
import CurriculumController from "@api/CurriculumController"
import LevelController from "@api/LevelController"
import Avatar from "@components/form/Avatar"
import Button from "@components/form/Button"
import Datepicker from "@components/form/Datepicker"
import Error from "@components/form/Error"
import Input from "@components/form/Input"
import Select from "@components/form/Select"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import readFileAsDataUrl from "@helpers/readFileAsDataUrl"
import toOptions from "@helpers/toOptions"
import useCategory from "@hooks/useCategory"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import {
  mutate,
  mutateAvatar,
  mutateSubscription,
  saveUser,
  updateAvatar,
} from "@redux/user.slice"
import { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import { unsubscribeUser } from "@src/domains/subscription/services"
import { logoutUser } from "@redux/user.slice"
import token from "@constants/token"

import CopyImage from "../../../shared/icons/copy.svg"

export default function ProfilePage() {
  const [error, setError] = useState()
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm()

  const curriculum = useCurriculumState(user, values => {
    for (const [key, value] of Object.entries(values)) {
      setValue(key, value)
    }
  })

  useTitle("Profile")
  useCategory("profile")
  useHeader({ className: "rounded-b-none" }, [])

  const next = async data => {
    dispatch(
      mutate({
        birthday: data.birthday,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        curriculumId: data.curriculumId.value,
        classesId: data.classesId.value,
        levelId: data.levelId.value,
      })
    )

    try {
      await dispatch(
        saveUser({
          role: user?.role,
          birthday: data.birthday,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          curriculumId: data.curriculumId.value,
          classesId: data.classesId.value,
          levelId: data.levelId.value,
        })
      ).unwrap()

      toast.success("Profile updated!")
      setError()
    } catch (err) {
      setError(err.message)
    }

    if (data.avatar?.[0]) {
      readFileAsDataUrl(data.avatar[0]).then(async avatar => {
        try {
          await dispatch(updateAvatar(avatar)).unwrap()
          dispatch(mutateAvatar(avatar))
        } catch (err) {
          toast.error(`Can't update the avatar: ${err.message}`)
        }
      })
    }
  }

  const unsubscribe = async () => {
    if (!window.confirm("Are you sure you want to usubscribe?")) return
    const res = await unsubscribeUser()
    if (res?.success) {
      toast.success("User was unsubscribed")
      localStorage.setItem("localUser", user)
      await dispatch(logoutUser()).unwrap()
      localStorage.removeItem(token)
      localStorage.removeItem("subscription")
      navigate("/login")
    } else {
      toast.error(res?.error)
    }
  }

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  return (
    <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
      <HeaderSubmenu
        className="relative h-[84px]"
        center={
          <div className="absolute z-10 left-1/2 top-0 -translate-x-1/2">
            <Avatar
              name="avatar"
              className="mx-auto"
              error={errors?.avatar}
              defaultAvatar={user.avatar}
              {...register("avatar")}
            />
          </div>
        }
      />
      <Container.Medium className="h-full">
        <form
          className="h-full flex flex-col relative"
          onSubmit={handleSubmit(next)}
        >
          <div className="h-full overflow-auto mt-[96px] mb-[36px]">
            <div className="grid gap-[32px] h-full content-center px-[2px]">
              <div className="grid gap-[24px]">
                <div className="grid grid-cols-3 gap-[24px]">
                  <Input
                    placeholder="Name"
                    defaultValue={user.firstName}
                    error={errors?.firstName}
                    {...register("firstName", {
                      required: { message: "Name is required", value: true },
                      minLength: {
                        message: "Name's length must be more than 1 character.",
                        value: 2,
                      },
                      maxLength: {
                        message:
                          "Name's length must be less than 51 characters.",
                        value: 50,
                      },
                    })}
                  />
                  <Input
                    placeholder="Last name"
                    defaultValue={user.lastName}
                    error={errors?.lastName}
                    {...register("lastName", {
                      required: {
                        message: "Last name is required",
                        value: true,
                      },
                      minLength: {
                        message:
                          "Last name's length must be more than 1 character.",
                        value: 2,
                      },
                      maxLength: {
                        message:
                          "Last name's length must be less than 51 characters.",
                        value: 50,
                      },
                    })}
                  />

                  <Input
                    type="text"
                    placeholder="Msisdn"
                    error={errors?.msisdn}
                    defaultValue={user.msisdn}
                    img={
                      <button
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(user.msisdn)
                        }}
                        className="absolute right-[10px] top-[50%] transform translate-y-[-50%]"
                      >
                        <img src={CopyImage} alt="copy" className="w-[24px]" />
                      </button>
                    }
                    disabled
                    {...register("msisdn")}
                  />
                </div>
                <div className="grid grid-cols-3 gap-[24px]">
                  <Select.Controller
                    name="curriculumId"
                    placeholder="Curriculum"
                    error={errors?.curriculumId}
                    options={curriculum.curriculumOptions}
                    value={curriculum.curriculumOption}
                    onChange={curriculum.handleCurriculumChange}
                    control={control}
                    isSearchable={false}
                    isClearable={false}
                    notRefresh
                    rules={{
                      required: {
                        message: "Curriculum is required",
                        value: true,
                      },
                    }}
                  />
                  <Select.Controller
                    name="levelId"
                    placeholder="Level"
                    options={curriculum.levelOptions}
                    value={curriculum.levelOption}
                    onChange={curriculum.handleLevelChange}
                    error={errors?.levelId}
                    control={control}
                    isSearchable={false}
                    isClearable={false}
                    notRefresh
                    rules={{
                      required: {
                        message: "Level is required",
                        value: true,
                      },
                    }}
                  />
                  <Select.Controller
                    name="classesId"
                    placeholder="Class"
                    options={curriculum.classOptions}
                    value={curriculum.classOption}
                    error={errors?.classesId}
                    control={control}
                    onChange={curriculum.handleClassChange}
                    isSearchable={false}
                    isClearable={false}
                    notRefresh
                    rules={{
                      required: {
                        message: "Class is required",
                        value: true,
                      },
                    }}
                  />
                </div>
                <div className="grid grid-cols-3 gap-[24px]">
                  <Datepicker.Controller
                    name="birthday"
                    placeholder="Date of birth"
                    isClearable
                    defaultValue={user.birthday}
                    error={errors?.birthday}
                    control={control}
                    notRefresh
                    rules={{
                      required: {
                        message: "Date of birth is required",
                        value: true,
                      },
                    }}
                  />
                  <Input
                    type="email"
                    placeholder="Email address"
                    error={errors?.email}
                    defaultValue={user.email}
                    {...register("email", {
                      required: {
                        message: "Email address is required",
                        value: true,
                      },
                    })}
                  />
                  <Input
                    type="text"
                    placeholder="Parents Code"
                    error={errors?.parentsCode}
                    defaultValue={user.parentsCode}
                    img={
                      <button
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(user.parentsCode)
                        }}
                        className="absolute right-[10px] top-[50%] transform translate-y-[-50%]"
                      >
                        <img src={CopyImage} alt="copy" className="w-[24px]" />
                      </button>
                    }
                    disabled
                    {...register("parentsCode")}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col items-center">
                  <div className="grid grid-cols-2 gap-x-[30px]">
                    <button
                      type="button"
                      onClick={unsubscribe}
                      className="bg-transparent border-[2px] rounded-[16px] text-red w-[250px] h-[54px] hover:bg-red hover:text-white transition"
                    >
                      Cancel Subscription
                    </button>
                    <Button>Save</Button>
                  </div>
                  {error && <Error message={error} centered />}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container.Medium>
    </div>
  )
}

/**
 * Three selects on a page (curriculums, levels and classes) are interconnected.
 * This hook syncs them.
 */
function useCurriculumState(user, onChange) {
  const [curriculumOption, setCurriculumOption] = useState({
    label: user.curriculumName,
    value: user.curriculumId,
  })

  const [levelOption, setLevelOption] = useState({
    label: user.levelName,
    value: user.levelId,
  })

  const [classOption, setClassOption] = useState({
    label: user.className,
    value: user.classId,
  })

  const { data: curriculums } = useQuery(
    "profile/curriculums",
    CurriculumController.getCurriculums
  )

  const { data: levels } = useQuery(
    `profile/${curriculumOption?.value}/levels`,
    () => LevelController.getLevels({ curriculumId: curriculumOption.value }),
    { enabled: Boolean(curriculumOption?.value) }
  )

  const { data: classes } = useQuery(
    `profile/${levelOption?.value}/classes`,
    () => ClassController.getClasses({ levelId: levelOption.value }),
    { enabled: Boolean(levelOption?.value) }
  )

  const curriculumOptions = useMemo(
    () => toOptions(curriculums?.data),
    [curriculums]
  )

  const levelOptions = useMemo(() => toOptions(levels?.data), [levels])

  const classOptions = useMemo(() => toOptions(classes?.data), [classes])

  const handleCurriculumChange = option => {
    setCurriculumOption(option)
    setLevelOption(null)
    setClassOption(null)

    onChange({
      curriculumId: option?.value,
      levelId: null,
      classesId: null,
    })
  }

  const handleLevelChange = option => {
    setLevelOption(option)
    setClassOption(null)

    onChange({
      levelId: option?.value,
      classesId: null,
    })
  }

  const handleClassChange = option => {
    setClassOption(option)

    onChange({ classesId: option?.value })
  }

  return {
    curriculumOption,
    levelOption,
    classOption,
    curriculumOptions,
    levelOptions,
    classOptions,
    handleCurriculumChange,
    handleLevelChange,
    handleClassChange,
  }
}
