import { configureStore } from '@reduxjs/toolkit'
import appReducer from './app.slice'
import headerReducer from './header.slice'
import userReducer from './user.slice'

export const store = configureStore({
  reducer: {
      app: appReducer,
      header: headerReducer,
      user: userReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})