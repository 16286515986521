import StatisticsCard from "@blocks/StatisticsCard";
import SummaryCard from "@blocks/SummaryCard";
import Container from "@components/layout/Container";
import useCategory from "@hooks/useCategory";
import useTitle from "@hooks/useTitle";
import PageTemplate from "@templates/PageTemplate";

export default function DashboardPage() {
  useTitle("Dashboard")
  useCategory("summary")

  return (
    <Container.Medium className="h-full">
      <PageTemplate>
        <div className="grid gap-[24px] items-start">
          <div className="grid gap-[16px]">
            <div className="bg-white rounded-[16px] p-[24px]">
              <h2 className="text-[16px] mb-[16px]">Summary Test</h2>
              <div className="grid grid-cols-3 gap-[16px]">
                <SummaryCard label="Total" value="5" />
                <SummaryCard label="Attempted" value="1/5" />
                <SummaryCard label="Remaining" value="2/5" />
              </div>
            </div>
          </div>
          <div className="grid gap-[16px]">
            <h2 className="text-[16px] p-[16px] pb-[0px]">Subjects</h2>
            <div className="grid grid-cols-2 gap-[16px]">
              <StatisticsCard label="All" value={75} />
              <StatisticsCard label="Biology" value={100} />
              <StatisticsCard label="Science" value={20} />
              <StatisticsCard label="English" value={0} />
            </div>
          </div>
        </div>
      </PageTemplate>
    </Container.Medium>
  )
}