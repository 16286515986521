import { setOptions } from "@redux/header.slice"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

export default function useHeader(options, deps) {
  const dispatch = useDispatch()

  deps = deps || [options]

  useEffect(() => {
    dispatch(setOptions(options))

    return () => dispatch(setOptions())
  }, deps)
}
