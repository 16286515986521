import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setOptions: (state, action) => {
      return action.payload || {}
    },
  },
})

export const { setOptions } = headerSlice.actions

export default headerSlice.reducer
