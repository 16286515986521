import React, { useEffect, useState } from "react"
import NewHeader from "@components/layout/NewHeader"
import NewFooter from "@components/layout/NewFooter"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import Input from "@components/form/Input"
import Button from "@components/form/Button"
import stockImage from "../../../shared/illustrations/contactusPageImage.png"
import TextArea from "@components/form/TextArea"
import NewInput from "@components/form/NewInput"
import InputPhone from "@components/form/InputPhone"
import { getPrivacyPolicy } from "@login/services"
import Modal from "@blocks/Modal"
import { ReactComponent as CloseIcon } from "@icons/close.svg"

export default function ContactUsPage() {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)
  const [privacyPolicy, setprivacyPolicy] = useState(null)
  const [clicked, setClicked] = useState(false)
  const [termsError, setTermsError] = useState("")

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      const res = await getPrivacyPolicy()
      if (res && res.data) {
        setprivacyPolicy(res.data.replace(/\n/g, "<br />"))
      } else {
        setprivacyPolicy("Unexpected error observed Try again later")
      }
    }
    fetchPrivacyPolicy()
  }, [])

  useEffect(() => {
    if (clicked) {
      setTermsError("")
    }
  }, [clicked])
  /* ---------------------------------------------------------------------*/
  // Validation Schema for Form

  const contactUsPageFormSchema = Yup.object().shape({
    firstname: Yup.string().trim().required(),
    lastname: Yup.string().trim().required(),
    email: Yup.string().trim().required(),
    phone: Yup.number().required(),
    message: Yup.string().required(),
  })
  /* ---------------------------------------------------------------------*/

  /* ---------------------------------------------------------------------*/
  // function for handling Form submission

  const handleSubmitAction = () => {}
  /* ---------------------------------------------------------------------*/

  return (
    <div>
      <NewHeader />
      <div className=" flex place-content-center">
        <div className="p-10 gap-10 place-items-center flex flex-row w-full ">
          {/* Form Container */}

          <div className="flex flex-col px-10 gap-10 w-1/2">
            <div className="flex flex-col gap-5">
              <div className="font-bold text-4xl"> Get in Touch</div>
              <div>Our friendly team would love to hear from you</div>
            </div>
            {/* Form */}
            <Formik
              enableReinitialize
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                phone: null,
                message: "",
              }}
              validationSchema={contactUsPageFormSchema}
              onSubmit={handleSubmitAction}
            >
              {({ values, errors, setFieldValue }) => {
                return (
                  <Form>
                    <div className="flex flex-col gap-8">
                      <div className="flex gap-10 w-[224]">
                        <div className="w-full">
                          <label>First name</label>
                          <NewInput
                            label=""
                            name="firstname"
                            id="firsname"
                            placeholder="First Name"
                          ></NewInput>
                        </div>
                        <div className="w-full">
                          <label>Last Name</label>
                          <NewInput label="" placeholder="Last Name"></NewInput>
                        </div>
                      </div>
                      <div>
                        <label>email</label>
                        <NewInput placeholder="your@email.com"></NewInput>
                      </div>
                      <div>
                        <label>phone</label>
                        <InputPhone label=""></InputPhone>
                      </div>
                      <div>
                        <label>Message</label>
                        <TextArea
                          className=" rounded-md border border-grey bg-transparent"
                          placeholder="Message"
                        ></TextArea>
                      </div>

                      <div className="flex flex-row gap-5">
                        <Field type="checkbox" value={false} />
                        <p>
                          You agree to our friendly{" "}
                          <button
                            className="underline underline-offset-4 hover:text-primary"
                            onClick={() => setIsPrivacyPolicyOpen(true)}
                          >
                            privacy policy.
                          </button>
                        </p>
                      </div>
                      <div>
                        <Button className="rounded-md w-full">
                          Send Message
                        </Button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>

          {/* Picture */}
          <div className="flex w-1/2 ">
            <img src={stockImage} alt="stock Footage" />
          </div>
        </div>
      </div>
      <NewFooter />
      <Modal
        isOpen={isPrivacyPolicyOpen}
        onRequestClose={() => {
          setIsPrivacyPolicyOpen(false)
        }}
        closeButtonHidden={true}
        className="w-[100%] bg-transparent max-w-[1000px] py-[0px]"
      >
        <div className="bg-white grid justify-items-center px-[20px] pb-[20px] pt-[40px] w-[100%] h-[100%] relative">
          <Close
            onClick={() => {
              setIsPrivacyPolicyOpen(false)
            }}
          />
          <div className="overflow-auto  max-h-[42rem]">
            <p
              dangerouslySetInnerHTML={{ __html: privacyPolicy }}
              className="text-[16px] overflow-auto"
            ></p>
          </div>
        </div>
      </Modal>
    </div>
  )
}
function Close({ onClick }) {
  return (
    <div
      className="absolute top-[20px] right-[20px] fill-black cursor-pointer"
      onClick={onClick}
    >
      {<CloseIcon />}
    </div>
  )
}
