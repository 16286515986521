/**
 * This function is useful to combine two or more function into one.
 * 
 * For instance, it's possible to use the helper to merge two `onChange()` functions.
 * 
 * @param  {(Function | undefined)[]} fn 
 * @returns {Function}
 */
export default function combineFn(...fn) {
  return (...args) => {
    fn.forEach(item => item?.(...args))
  }
}