import Container from "@components/layout/Container"
import { twMerge } from "tailwind-merge"

export default function HeaderSubmenu({ className = '', left, center, right }) {
  return (
    <Container className={twMerge(`
        bg-primary 
        rounded-b-[20px]
        pb-[24px]
        flex
        justify-between
        items-end
    `, className)}>
      <div>
        {left}
      </div>
      <div>
        {center}
      </div>
      <div>
        {right}
      </div>
    </Container>
  )
}
