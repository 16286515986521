import React from "react"
import { Link } from "react-router-dom"
import Avatar from "./Avatar"

export default React.memo(ChildCard)

function ChildCard(props) {
  return (
    <Link to={props.to} className="cursor-pointer">
      <div className="flex gap-[16px] items-center">
        <div className="w-[50px] h-[50px]">
          <Avatar
            avatar={props.photo}
            foregroundClassName="border-primary border-2"
          />
        </div>

        <div>
          <p>{props.name}</p>
          <div className="flex gap-[12px]">
            <div className="flex gap-[8px] items-center">
              <div className="w-[16px] h-[16px] bg-grey rounded-full" />
              <p>
                <small className="text-[12px]">{props.level}</small>
              </p>
            </div>
            <div className="flex gap-[8px] items-center">
              <div className="w-[16px] h-[16px] bg-grey rounded-full" />
              <p>
                <small className="text-[12px]">{props.class}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
