import { useState } from "react"
import LessonController from "@api/LessonController"
import Badge from "@blocks/Badge"
import { useModal } from "@blocks/Modal"
import Button from "@components/form/Button"
import ButtonPdf from "@components/form/ButtonPdf"
import ButtonPlay from "@components/form/ButtonPlay"
import Container from "@components/layout/Container"
import VideoModal from "@components/modals/VideoModal"
import titlify from "@helpers/titlify"
import useQuestionsQuery from "@hooks/queries/useQuestionsQuery"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { Link, useParams } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import PdfPreviewModal from "@blocks/PdfPreviewModal"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import withIcons from "@helpers/withIcons"

import RateIcon from "@icons/rate.svg"

export default function LessonPage() {
  const { id } = useParams()
  const { data: questions } = useQuestionsQuery(id)
  const [isLoading, setIsLoading] = useState(true)
  const [lesson, setLesson] = useState(null)
  const [error, setError] = useState(null)

  useTitle(titlify(lesson?.name, isLoading, error))
  useCategory("lessons")
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      const getLesson = async () => {
        setIsLoading(true)
        try {
          const res = await withIcons(
            LessonController.getLesson({ lessonId: id }).then(
              response => response.data
            )
          )
          setLesson(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getLesson()
    }
  }, [id])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (error === "Record not found") {
    return (
      <ErrorPageTemplate title="Not found">
        We can't find the lesson. Please, return to the main page.
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.Wide className="h-full">
      <PageTemplate>
        <Banner
          duration={
            questions?.data?.length ? `${questions?.data?.length} min` : `1 min`
          }
          lesson={lesson}
        />
        <div className="pt-[48px] pb-[24px]">
          <div className="mb-[32px] leading-relaxed">
            <p className="text-[18px] font-semibold">{lesson?.subtitle}</p>
            <h2 className="text-[25px] font-semibold">{lesson?.name}</h2>
          </div>
          <div className="grid gap-[24px] font-normal leading-loose mb-[48px]">
            <p>{lesson?.content}</p>
          </div>
          <div className="flex justify-center gap-[16px]">
            {!lesson?.hasUserFeedback && lesson?.status === "COMPLETED" && (
              <Link to={`/lessons/${lesson?.id}/rate`}>
                <Button secondary>Rate</Button>
              </Link>
            )}
            {Boolean(questions?.data?.length) && (
              <Link to={`/lessons/${lesson?.id}/test`}>
                <Button>{getButtonLabelBasedOnStatus(lesson?.status)}</Button>
              </Link>
            )}
          </div>
        </div>
      </PageTemplate>
    </Container.Wide>
  )
}

function Banner({ duration, className, lesson, ...props }) {
  const videoModal = useModal()

  const [pdfFileLink, setPdfFileLink] = useState(null)
  const [totalFeedbacks, setTotalFeedbacks] = useState(1)
  const [feedbacks, setFeedbacks] = useState(null)
  const [rating, setRating] = useState(0)

  const onPreviewPdf = async () => {
    const res = await LessonController.getLessonFile({ uuid: lesson.uuid })
    setPdfFileLink(res.data)
  }

  useEffect(() => {
    const getLessonsFeedback = async () => {
      const res = await LessonController.getFeedbacks({
        lessonId: lesson.id,
        length: totalFeedbacks,
      })
      if (res && res?.data && res?.total > 0) {
        setTotalFeedbacks(res.total === 0 ? 1 : res.total)
        setFeedbacks(res.data)
      }
    }
    getLessonsFeedback()
  }, [totalFeedbacks, lesson])

  useEffect(() => {
    if (feedbacks?.length > 0) {
      let totalRate = 0
      for (let i = 0; i < feedbacks?.length; i++) {
        totalRate += feedbacks[i].rating
      }
      setRating((totalRate / feedbacks?.length).toFixed(1))
    }
  }, [feedbacks])

  return (
    <>
      <div
        className={twMerge(
          `
          w-full
          h-[220px]
          bg-primary
          flex
          flex-col
          justify-between
          p-[36px]
          rounded-[20px]
        `,
          className
        )}
        style={{
          backgroundImage: lesson?.icon && `url(${lesson?.icon})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
        {...props}
      >
        <div className="flex justify-start">
          {feedbacks?.length > 0 && lesson.hasUserFeedback && (
            <div className="flex items-center bg-yellow px-[12px] py-[4px] rounded-[50px]">
              <img alt="rate" className="w-[21px] h-[21px]" src={RateIcon} />
              <span className="ml-[6px] text-white text-[14px] leading-[14px]">
                {rating}
              </span>
            </div>
          )}
        </div>
        <div className="flex justify-between items-end">
          <Badge.Fill
            color="var(--color-white)"
            backgroundColor="rgba(255,255,255,0.2)"
          >
            {duration}
          </Badge.Fill>
          <div className="flex gap-[16px]">
            {lesson?.hasVideo && (
              <ButtonPlay
                title="Play Video"
                onClick={() =>
                  videoModal.open({
                    request: LessonController.getLessonVideoStreamUrl({
                      uuid: lesson?.uuid,
                    }),
                  })
                }
              />
            )}
            {lesson?.hasFile && (
              <ButtonPdf title="View PDF" onClick={() => onPreviewPdf()} />
            )}
          </div>
        </div>
      </div>
      <VideoModal {...videoModal.props} />
      <PdfPreviewModal
        pdfFileLink={pdfFileLink}
        setPdfFileLink={setPdfFileLink}
      />
    </>
  )
}

function getButtonLabelBasedOnStatus(status) {
  if (status === "ON_GOING") return "Continue Test"
  if (status === "COMPLETED") return "Restart Test"
  return "Start Test"
}
