import { setCategory } from "@redux/app.slice"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

export default function useCategory(category) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setCategory(category))

        return () => dispatch(setCategory())
    }, [category])
}