import formatDuration from "date-fns/formatDuration"
import intervalToDuration from "date-fns/intervalToDuration"

export default function getHumanReadableTimeInterval(date) {
  const start = date instanceof Date ? date : new Date(date)
  const end = new Date()
  const duration = intervalToDuration({ start, end })
  const format = [getHighestPeriod(duration)]
  return formatDuration(duration, { format })
}

function getHighestPeriod(duration) {
  for (const period of sortedTimePeriods) {
    if (duration[period])
      return period
  }
}

const sortedTimePeriods = [
  "years",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
]
