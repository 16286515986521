
import { getResData } from "./getResData";
import axios from "axios"

export const getTermsAndConditions = async () => {
  try {
    const res = await axios.get("/terms/and/conditions");
    return getResData(res.data);
  } catch (error) {
    console.error("getTermsAndConditions error => ", error);
    console.log("Error Response getTermsAndConditions => ", error.response);
    return null;
  }
};

export const getPrivacyPolicy = async () => {
  try {
    const res = await axios.get("/privacy/policy")
    return getResData(res.data)
  } catch (error) {
    console.log("getPrivacyPolicy error => ", error)
    console.log("Error Response getPrivacyPolicy => ", error.response)
    return null
  }
};