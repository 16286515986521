import React from 'react'
import ErrorCard from './ErrorCard'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return (
        <div className='centered w-full h-screen bg-light-primary'>
          <ErrorCard title="Unexpected error" to={false} button="Reload" href="/">Error description: {this.state.error.message}</ErrorCard>
        </div>
      )
    }

    return this.props.children
  }
}
