import axios from "axios"

export default class ChapterController {
  static async getChapters({ subjectId, length, start = 0 }) {
    const result = await axios.post("/user/chapter/list", {
      length,
      start,
      subjectId,
    })
    return result.data
  }

  static async getChapterFile({ uuid }) {
    const result = await axios.get(`/user/chapter/file/get/${uuid}`)
    return result.data
  }
}
