import FileResourceController from "@api/FileResourceController"

export default async function withBackgrounds(promise) {
  const response = await promise

  const promises = response.data.map((item, idx) => {
    return FileResourceController.getBgImage(item.uuid)
      .then(background => { response.data[idx].background = background })
  })

  await Promise.allSettled(promises)

  return response
}