import ReactDropdown from "react-dropdown"
import { ReactComponent as ArrowBottom } from "@icons/arrow_bottom.svg"
import { ReactComponent as ArrowTop } from "@icons/arrow_top.svg"

export default function Dropdown(props) {
  return (
    <ReactDropdown
      {...props}
      className={props.dropdownClassName}
      controlClassName={`bg-transparent border-0 text-white flex items-center justify-between p-[12px] hover:shadow-none cursor-pointer ${props.dropdownClassName}`}
      placeholderClassName="mr-[16px] text-[22px] whitespace-nowrap overflow-hidden text-ellipsis"
      menuClassName={`bg-light-primary border-white rounded-[8px] [&_>_div]:text-dark-grey [&_>_div:hover]:bg-white [&_>_.is-selected]:text-white [&_>_.is-selected:hover]:bg-primary [&_>_.is-selected]:bg-primary ${props.dropdownClassName}`}
      arrowClosed={
        <ArrowBottom className="stroke-white" width="16px" height="16px" />
      }
      arrowOpen={
        <ArrowTop className="stroke-white" width="16px" height="16px" />
      }
    />
  )
}
