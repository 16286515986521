import React from "react"
import newLogo from "../../icons/NewLogo.svg"
import { useNavigate } from "react-router-dom"


export default function NewHeader() {

  const navigate = useNavigate()
  return (
    <div className="flex flex-col p-2 shadow-sm bg-light-primary">
      <div className="px-[60px] py-2 flex flex-row justify-between">
        <div className="flex flex-row gap-5">
          <div className="flex">
            <img
              src={newLogo}
              alt="Logo"
              width={181.59}
              height={34.58}
            />
          </div>
        </div>
        <div className="flex gap-5">
            <button
              type="button"
              onClick={()=>{navigate("/login")}}
              className=" text-white hover:text-black rounded-lg px-[10px] w-24 hover:border-[#49A259] hover:border-2 hover:shadow-md hover:bg-[#F1F1F3] bg-[#49A259]"
            >
              Login
            </button>
          </div>
      </div>
    </div>
  )
}
