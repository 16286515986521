import SubjectController from "@api/SubjectController"
import { useQuery } from "react-query"

export default function useSubjectByIdQuery(id) {
  return useQuery(`subject-${id}`, () =>
    SubjectController.getSubjects().then(subjects =>
      subjects.data.find(subject => subject.id === parseInt(id))
    )
  )
}
