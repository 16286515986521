import when from "@helpers/when";

export default function Error({ message, centered }) {
  return (
    <div
      className={`
        mt-[6px]
        leading-tight
        
        ${when(centered, 'text-center')}
      `}
    >
      <small className="text-red">{message}</small>
    </div>
  )
}
