import { ReactComponent as PDFIcon } from "@icons/pdf.svg"
import { ReactComponent as DownloadIcon } from "@icons/download.svg"
import Card from "./Card"
import React from "react"

export default React.memo(BookCard)

function BookCard({ title, description, onDownloadClick }) {
  return (
    <Card
      className={`
        p-[16px]
        bg-transparent
        hover:bg-white
        transition
        cursor-pointer
      `}
    >
      <div className="flex items-center">
        <div className="rounded-full bg-primary w-[48px] h-[48px] centered fill-white flex-shrink-0">
          <PDFIcon />
        </div>
        <div className="w-full mr-[16px] ml-[16px]">
          <h3>{title}</h3>
          <p>
            <small className="text-[12px]">{description}</small>
          </p>
        </div>
        <div
          className="rounded-[12px] w-[56px] h-[56px] centered bg-light-grey stroke-primary flex-shrink-0"
          onClick={onDownloadClick}
        >
          <DownloadIcon />
        </div>
      </div>
    </Card>
  )
}
