import Notification from "@blocks/Notification"
import Container from "@components/layout/Container"
import getHumanReadableTimeInterval from "@helpers/getHumanReadableTimeInterval"
import useAccordion from "@hooks/useAccordion"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import NotificationController from "@api/NotificationController"
import { useState, useEffect } from "react"

export default function NotificationsPage() {
  useTitle("Notifications")
  useCategory("notifications")
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [notifications, setNotifications] = useState(null)
  const [error, setError] = useState(null)
  const [total, setTotal] = useState(1)

  useEffect(() => {
    const getNotifications = async () => {
      setIsLoading(true)
      try {
        const res = await NotificationController.getNotifications()
        setNotifications(res)
        setTotal(res.total)
      } catch (err) {
        console.log("error message", err?.message)
        setError(err?.message)
      }
      setIsLoading(false)
    }
    getNotifications()
  }, [total])

  const accordion = useAccordion()

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (!notifications?.data?.length) {
    return (
      <ErrorPageTemplate title="No notifications">
        We don't have any notifications for you at the moment
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.Medium className="h-full">
      <PageTemplate>
        {notifications?.data?.map((notification, idx) => (
          <Notification
            key={notification.id}
            title={`Notification ${idx + 1}`}
            time={`${getHumanReadableTimeInterval(notification.createdAt)} ago`}
            visible={accordion.active === idx}
            onClick={() => accordion.toggle(idx)}
          >
            {notification.content}
          </Notification>
        ))}
      </PageTemplate>
    </Container.Medium>
  )
}
