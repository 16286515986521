import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import SubjectCard from "@blocks/SubjectCard"
import Container from "@components/layout/Container"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import PageTemplate from "@templates/PageTemplate"
import SubjectController from "@api/SubjectController"
import withBackgrounds from "@helpers/withBackgrounds"
import { useNavigate } from "react-router-dom"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"

export default function SubjectsPage() {
  useTitle("Subjects")
  useCategory("home")
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const [subjects, setSubjects] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  localStorage.removeItem("permanentUser")
  localStorage.removeItem("justLoggedIn")

  useEffect(() => {
    if (user) {
      const getSubjects = async () => {
        setIsLoading(true)
        try {
          const res = await withBackgrounds(SubjectController.getSubjects())
          setSubjects(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getSubjects()
    }
  }, [user])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.Wide className="h-full">
      <PageTemplate title="What would you like to learn today?">
        <div className="grid grid-cols-3 gap-[24px] items-start">
          {subjects &&
            subjects?.data?.map((subject, idx) => (
              <SubjectCard
                key={subject.id}
                illustration={subject.background}
                {...getColorsByIdx(idx)}
                to={`/subjects/${subject.id}/chapters`}
              >
                {subject.name}
              </SubjectCard>
            ))}
        </div>
      </PageTemplate>
    </Container.Wide>
  )
}

function getColorsByIdx(idx) {
  idx = idx % colorcoded?.length
  return colorcoded[idx]
}

const colorcoded = [
  { backgroundColor: "rgba(229, 55, 55, 0.15)", color: "rgba(229, 55, 55, 1)" },
  { backgroundColor: "rgba(2, 189, 178, 0.15)", color: "rgba(2, 189, 178, 1)" },
  {
    backgroundColor: "rgba(133, 102, 221, 0.15)",
    color: "rgba(133, 102, 221, 1)",
  },
  {
    backgroundColor: "rgba(33, 198, 106, 0.15)",
    color: "rgba(33, 198, 106, 1)",
  },
  {
    backgroundColor: "rgba(210, 51, 185, 0.15)",
    color: "rgba(228, 76, 222, 1)",
  },
  {
    backgroundColor: "rgba(35, 119, 255, 0.15)",
    color: "rgba(35, 119, 255, 1)",
  },
  { backgroundColor: "rgba(85, 85, 85, 0.15)", color: "rgba(85, 85, 85, 1)" },
  {
    backgroundColor: "rgba(243, 132, 70, 0.15)",
    color: "rgba(243, 132, 70, 1)",
  },
  {
    backgroundColor: "rgba(251, 186, 40, 0.15)",
    color: "rgba(251, 186, 40, 1)",
  },
]
