import when from "@helpers/when";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

function RadioTest({ correct, wrong, letter, label, className, ...props }, ref) {
  return (
    <div className="w-full">
      <div className={
        twMerge(`
          px-[24px]
          rounded-full

          ${when(correct, `
            bg-secondary
          `)}

          ${when(wrong, `
            bg-red
          `)}
        `)}>
        <input ref={ref} type="radio" className={`
          w-[1px]
          h-[1px]
          opacity-0
          absolute
          peer
        `} {...props} />
        <label 
          htmlFor={props.id} 
          className={twMerge(`
            w-full
            flex
            items-center
            gap-[12px]
            py-[12px]
            pl-[6px]
            border-b-[1px]
            border-b-grey
            cursor-pointer
            peer-checked:[&_>_span:last-child]:opacity-100
            peer-checked:[&_>_span_>_span:last-child]:bg-white
            peer-disabled:[&_>_span:first-child]:text-typography-grey
            peer-disabled:text-typography-grey
            peer-disabled:[&_>_span:last-child]:bg-typography-grey
            peer-disabled:cursor-auto
            peer-disabled:border-b-transparent
          `, 
          when(correct || wrong, `
            text-white
            [&_>_span:last-child]:opacity-100
            [&_>_span_>_span:last-child]:bg-white
            [&_>_span:first-child]:text-white
            peer-disabled:text-white
            peer-disabled:[&_>_span:first-child]:text-white
          `),
          when(correct, `
            [&_>_span:last-child]:bg-[#48B6D8]
            peer-disabled:[&_>_span:last-child]:bg-[#48B6D8]
          `), 
          when(wrong, `
            [&_>_span:last-child]:bg-[#FC8787]
            peer-disabled:[&_>_span:last-child]:bg-[#FC8787]
          `), className)}
        >
          <span className="text-[20px] text-primary flex-shrink-0">{letter}.</span> 
          <span className="w-full">{label}</span>
          <span className="w-[24px] h-[24px] bg-primary rounded-full flex-shrink-0 centered opacity-[.15]">
            <span className="w-[8px] h-[8px] bg-transparent rounded-full"></span>
          </span>
        </label>
      </div>
    </div>
  )
}

export default forwardRef(RadioTest)