import useCategory from "@hooks/useCategory"
import CurriculumExistsMiddleware from "@middleware/CurriculumExistsMiddleware"
import LevelExistsMiddleware from "@middleware/LevelExistsMiddleware"
import MainTemplate from "@templates/MainTemplate"
import { Route, Routes } from "react-router-dom"
import ClassesPage from "./pages/ClassesPage"
import CurriculumPage from "./pages/CurriculumPage"
import LevelsPage from "./pages/LevelsPage"
import ParentsCodePage from "./pages/ParentsCodePage"
import ProfilePage from "./pages/ProfilePage"
import RolesPage from "./pages/RolesPage"

export default function RegistrationRoutes() {
  useCategory("registration")

  return (
    <Routes>
      <Route element={<MainTemplate />}>
        <Route index element={<RolesPage />} />

        <Route path="profile" element={<ProfilePage />} />

        <Route path="code" element={<ParentsCodePage />} />

        <Route path="curriculum" element={<CurriculumPage />} />

       
          <Route path="levels" element={<LevelsPage />} />

          <Route path="classes" element={<ClassesPage />} />
      </Route>
    </Routes>
  )
}
