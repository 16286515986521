import Card from "./Card"
import { ReactComponent as MicroscopeIcon } from '@icons/microscope.svg';
import CircleGraph from "./CircleGraph";
import when from "@helpers/when";
import { twMerge } from "tailwind-merge";
import React from "react";

export default React.memo(StatisticsCard)

function StatisticsCard({ label, value, active, onClick }) {
  return (
    <Card 
      className={twMerge(`
        rounded-[30px] 
        px-[24px] 
        py-[16px] 
        group 
        hover:shadow-lg
        cursor-pointer
        transition-all

        ${when(active, `
          bg-primary
          shadow-lg
        `)}
      `)}
      onClick={onClick}
    >
      <div className="flex items-center gap-[16px]">
        <div className="flex-shrink-0">
          <div className={twMerge(`
            rounded-full 
            centered 
            bg-light-primary 
            w-[30px] 
            h-[30px] 
            fill-primary 
            transition

            ${when(active, `
              bg-[#37CC79]
              fill-white
            `)}
          `)}>
            <MicroscopeIcon />
          </div>
        </div>
        <div className={`
          w-full 
          transition

          ${when(active, `
            text-white
          `)}
        `}>{label}</div>
        <div><CircleGraph value={value} active={active} /></div>
      </div>
    </Card>
  )
}
