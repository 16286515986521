import Button from "@components/form/Button"
import RadioTest from "@components/form/RadioTest"
import combineFn from "@helpers/combineFn"
import letters from "@helpers/letters"
import { useState } from "react"
import { useForm } from "react-hook-form"

export default function Question({
  visible,
  isLast,
  question,
  answer,
  onSubmit,
  onContinueClick,
  onFinishClick,
}) {
  const [chosen, setChosen] = useState()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleChoice = data => setChosen(parseInt(data.optionId))

  const inputValidation = register("optionId", {
    required: { value: true, message: "Please, select an answer." },
  })

  if (!visible) return null

  return (
    <form
      className="w-[460px]"
      onSubmit={handleSubmit(
        combineFn(handleChoice, data => onSubmit(question.id, data))
      )}
    >
      <div className="mb-[24px]">
        {question.options.map((option, idx) => (
          <RadioTest
            key={option.id}
            id={`test_option_${question.id}_${option.id}`}
            value={option.id}
            correct={chosen === option.id && answer?.correct === true}
            wrong={chosen === option.id && answer?.correct === false}
            letter={letters[idx].toUpperCase()}
            label={option.option}
            disabled={answer || question.attempted}
            {...inputValidation}
          />
        ))}
      </div>
      <AnswerStatus answer={answer} />
      <div className="flex justify-center">
        {(isLast && question.attempted) || (isLast && answer) ? (
          <Button type="button" onClick={onFinishClick}>
            Finish
          </Button>
        ) : answer ? (
          <Button type="button" onClick={onContinueClick}>
            Continue
          </Button>
        ) : (
          <Button>Check</Button>
        )}
      </div>
      {errors?.optionId && <div className="text-red mt-[16px] text-center">{errors?.optionId?.message}</div>}
      {errors?.test_option_1 && (
        <div className="text-red mt-[16px] text-center">
          {errors.test_option_1.message}
        </div>
      )}
    </form>
  )
}

function AnswerStatus({ answer }) {
  if (!answer) return null

  return (
    <div className="mb-[36px]">
      <div
        className={`mb-[4px] text-center ${
          answer.correct ? "text-secondary" : "text-red"
        }`}
      >
        {answer.correct ? "A correct answer" : "A wrong answer"}
      </div>
      {/* {!answer.correct && (
        <div className="text-center text-typography-grey">
          <small>Hint: {answer.hint}</small>
        </div>
      )} */}
    </div>
  )
}
