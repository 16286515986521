import ResultsCard from "@blocks/ResultsCard"
import Button from "@components/form/Button"
import Container from "@components/layout/Container"
import useLessonByIdQuery from "@hooks/queries/useLessonByIdQuery"
import useCategory from "@hooks/useCategory"
import useTitle from "@hooks/useTitle"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { Link, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import { useState, useEffect } from "react"
import TestController from "@api/TestController"

export default function TestResultsPage() {
  useTitle("Test Results")
  useCategory("lessons")
  const navigate = useNavigate()

  const { id } = useParams()
  const { data: lesson } = useLessonByIdQuery(id)
  const [results, setResults] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (id) {
      const getResults = async () => {
        setIsLoading(true)
        try {
          const res = await TestController.getResult({ lessonId: id })
          setResults(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getResults()
    }
  }, [id])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (error) {
    return (
      <ErrorPageTemplate title="Error">
        We tried to get the test results, but received the following error: "
        {error}"
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.XS className="h-full">
      <PageTemplate>
        <div className="text-center mb-[48px]">
          <h2 className="font-semibold text-primary mb-[4px]">
            {lesson?.name || "Loading..."}
          </h2>
          <p>Results</p>
        </div>
        <div className="grid gap-[24px] mb-[48px]">
          <div className="flex justify-center gap-[24px] px-[20px]">
            <ResultsCard
              label="Questions"
              score={results?.data?.questionsCount}
              color="var(--color-black)"
              className="w-[100%]"
            />
          </div>
          <div className="flex justify-center gap-[24px]">
            <ResultsCard
              label="Right Answers"
              score={results?.data?.rightCount}
              color="var(--color-primary)"
            />
            <ResultsCard
              label="Wrong Answers"
              score={results?.data?.wrongCount}
              color="var(--color-red)"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-[12px]">
          {!lesson?.hasUserFeedback && lesson?.status === "COMPLETED" && (
            <Link to={`/lessons/${id}/rate`}>
              <Button secondary>Rate</Button>
            </Link>
          )}
        </div>
      </PageTemplate>
    </Container.XS>
  )
}
