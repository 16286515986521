import token from "@constants/token"
import { fetchUser } from "@redux/user.slice"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

export default function useAuth() {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(
    localStorage.getItem(token) ? "loading" : "completed"
  )

  useEffect(() => {
    if (!localStorage.getItem(token)) return

    dispatch(fetchUser())
      .then(() => setStatus("completed"))
  }, [])

  return status
}
