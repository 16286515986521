import { ReactComponent as PlusIcon } from '@icons/plus.svg'

export default function ButtonAdd({ children, ...props }) {
  return (
    <button className='flex gap-[16px] items-center' {...props}>
      <span className='w-[50px] h-[50px] centered bg-primary stroke-white rounded-full'><PlusIcon /></span>
      <span className='text-primary'>{children}</span>
    </button>
  )
}
