import GuestMiddleware from "@middleware/GuestMiddleware"
import MsisdnExistsMiddleware from "@middleware/MsisdnExistsMiddleware"
import { Route, Routes } from "react-router-dom"
import LoginPage from "./pages/LoginPage"
import OtpPage from "./pages/OtpPage"

export default function LoginRoutes() {
  return (
    <Routes>
      <Route element={<GuestMiddleware />}>
        <Route index element={<LoginPage />} />

        <Route path="otp" element={<MsisdnExistsMiddleware />}>
          <Route index element={<OtpPage />} />
        </Route>
      </Route>
    </Routes>
  )
}
