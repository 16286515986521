import { forwardRef } from "react";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Error from "./Error";
import Indicator from "./Indicator";

function InputPhone({ className, onFocus, onBlur, onChange, error, defaultValue = '+252', ...props }, ref) {
  const [input, setInput] = useState(defaultValue)
  const [focus, setFocus] = useState()

  const handleChange = (...args) => {
    setInput(args[0].target.value)
    return onChange?.(...args)
  }

  const handleFocus = (...args) => {
    setFocus(true)
    return onFocus?.(...args)
  }

  const handleBlur = (...args) => {
    setFocus(false)
    return onBlur?.(...args)
  }

  return (
    <div>
      <div className="relative">
        <input 
          type="tel" 
          className={twMerge(`
            p-[12px]
            border-grey
            border-[1px]
            rounded-[8px]
            w-full
            pl-[42px]
          `, className)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          defaultValue={defaultValue}
          onChange={handleChange}
          {...props} 
          ref={ref}
        />
        <Indicator {...{ focus, input, error }} />
      </div>
      {error?.message && <Error message={error.message} />}
    </div>
  )
}

export default forwardRef(InputPhone)