import { useState, useEffect } from "react"
import ChapterCard from "@blocks/ChapterCard"
import Container from "@components/layout/Container"
import HeaderSubmenu from "@components/layout/HeaderSubmenu"
import pluralize from "@helpers/pluralize"
import useChapterByIdQuery from "@hooks/queries/useChapterByIdQuery"
import useCategory from "@hooks/useCategory"
import useHeader from "@hooks/useHeader"
import useTitle from "@hooks/useTitle"
import { ReactComponent as PDFIcon } from "@icons/pdf.svg"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import PageTemplate from "@templates/PageTemplate"
import { useParams } from "react-router-dom"
import PdfPreviewModal from "@blocks/PdfPreviewModal"
import ChapterController from "@api/ChapterController"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import LessonController from "@api/LessonController"
import withIcons from "@helpers/withIcons"
import { toast } from "react-toastify"

export default function ChapterLessonsPage() {
  const { subjectId, chapterId } = useParams()
  const { data: chapter } = useChapterByIdQuery(subjectId, chapterId)

  useTitle(chapter?.name || "Loading...")
  useCategory("home")
  useHeader({ className: "rounded-b-none" }, [])
  const navigate = useNavigate()

  const [pdfFileLink, setPdfFileLink] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [lessons, setLessons] = useState(null)
  const [error, setError] = useState(null)

  const onPreviewPdf = async () => {
    const res = await ChapterController.getChapterFile({ uuid: chapter.uuid })
    console.log(res.data)
    if (res?.data) {
      setPdfFileLink(res.data)
    } else {
      console.log(res.data)
      setPdfFileLink("")

      toast.warning("No Chapter Exercise yet")
    }
  }

  useEffect(() => {
    if (subjectId && chapterId) {
      const getLessons = async () => {
        setIsLoading(true)
        try {
          const res = await withIcons(
            LessonController.getLessons({ subjectId, chapterId })
          )
          setLessons(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getLessons()
    }
  }, [subjectId, chapterId])

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (!lessons?.data?.length) {
    return (
      <ErrorPageTemplate
        title="No lessons"
        card={{ to: `/subjects/${subjectId}/chapters` }}
      >
        We haven't added lessons to this chapter yet. Please, come back later.
      </ErrorPageTemplate>
    )
  }

  return (
    <>
      <div className="h-full grid grid-rows-[auto_minmax(0,_1fr)]">
        <HeaderSubmenu
          left={
            <div className="flex text-white fill-white">
              <p className="mr-[36px]">
                {lessons.total} {pluralize("Lesson", lessons.total)}
              </p>
              {/* <p className="flex items-center">
              <span className="mr-[8px]">
                <RefreshIcon />
              </span>
              <Link to={`/subjects/${subjectId}/years`}>Previous Years</Link>
            </p> */}
            </div>
          }
          right={
            <button
              onClick={() => onPreviewPdf()}
              className="flex items-center text-white fill-white"
            >
              Chapter Exercise
              <div className="rounded-full bg-primary w-[48px] h-[48px] centered fill-white flex-shrink-0">
                <PDFIcon />
              </div>
            </button>
          }
        />
        <Container.Medium className="h-full flex flex-col justify-center relative py-[32px]">
          <PageTemplate title="Select a lesson to get started">
            <div className="grid grid-cols-2 gap-[24px] items-start">
              {lessons.data.map(lesson => (
                <ChapterCard
                  key={lesson.id}
                  to={`/lessons/${lesson.id}`}
                  illustration={lesson.icon}
                  title={lesson.name}
                  description={lesson.subtitle}
                />
              ))}
            </div>
          </PageTemplate>
        </Container.Medium>
        <PdfPreviewModal
          pdfFileLink={pdfFileLink}
          setPdfFileLink={setPdfFileLink}
        />
      </div>
    </>
  )
}
