import when from "@helpers/when"
import { twMerge } from "tailwind-merge"

export default function CircleGraph({ value, active }) {
  return (
    <div
      className={`
      relative
      w-[64px]
      h-[64px]
      centered
      rounded-full
    `}
    >
      <svg viewBox="0 0 100 100">
        <mask id="clip">
          <circle cx="50" cy="50" r="49" fill="#fff" />
          <circle cx="50" cy="50" r="47" fill="#000" />
        </mask>
        <circle
          cx="50"
          cy="50"
          r="50"
          className={twMerge(`
            fill-[#C5CBD1] 

            ${when(active, `fill-[#64D797]`)}
          `)}
          mask="url(#clip)"
        />
        <circle
          cx="50"
          cy="50"
          r="48"
          fill="transparent"
          stroke={getColorByValue(value)}
          strokeWidth="4"
          strokeDasharray="301"
          strokeDashoffset={getOffsetByCircumference(value, 301)}
          className={twMerge(`
            transition 
            -rotate-90 
            origin-center 

            ${when(active, `!stroke-white`)}
          `)}
        />
      </svg>
      <div
        className={twMerge(`
          transition 
          absolute-centered 
          font-bold 
          text-[16px] 

          ${when(active, `
            !text-white
          `)}
        `)}
        style={{ color: getColorByValue(value) }}
      >
        {value}%
      </div>
    </div>
  )
}

const breakpoints = [
  { percentage: 0, color: "#C4CBD2" },
  { percentage: 1, color: "var(--color-red)" },
  { percentage: 50, color: "var(--color-secondary)" },
].sort(byPercentage)

function byPercentage(direction = "asc") {
  return (a, b) =>
    direction === "asc"
      ? a.percentage - b.percentage
      : b.percentage - a.percentage
}

function getColorByValue(value) {
  let result = breakpoints[0]

  for (const breakpoint of breakpoints) {
    if (value <= breakpoint.percentage) break

    result = breakpoint
  }

  return result.color
}

function getOffsetByCircumference(value, radius) {
  return -(radius * (1 - value / 100))
}
