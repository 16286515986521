import { ReactComponent as BlocksIcon } from "@icons/blocks.svg"
import { ReactComponent as ArrowRightIcon } from "@icons/arrow_right.svg"
import Card from "./Card"
import React from "react"

export default React.memo(ClassCard)

function ClassCard({ children, ...props }) {
  return (
    <Card {...props} className="cursor-pointer">
      <div
        className={`
          relative
          pt-[32px]
          pb-[16px]
          px-[24px] 
          min-h-[140px]
          flex
          flex-col
          justify-between
          group
        `}
      >
        <div className="flex">
          <div className="bg-light-primary fill-primary w-[32px] h-[32px] rounded-full centered flex-shrink-0">
            <BlocksIcon />
          </div>
          <p className="ml-[12px] pt-[4px] overflow-hidden text-ellipsis">
            {children}
          </p>
        </div>
        <Arrow />
      </div>
    </Card>
  )
}

function Arrow() {
  return (
    <div
      className={`
        absolute
        opacity-0
        w-[24px]
        h-[24px]
        bg-primary
        rounded-full
        centered
        stroke-white
        group-hover:opacity-100
        group-hover:-translate-y-[16px] 
        transition
        bottom-0
        right-[16px]
      `}
    >
      <ArrowRightIcon />
    </div>
  )
}
