import root from "@boot/root"
import ReactModal from "react-modal"
import { useState } from "react"
import { twMerge } from "tailwind-merge"
import { ReactComponent as CloseIcon } from "@icons/close.svg"

ReactModal.setAppElement(root)

export default function Modal({
  children,
  className,
  closeButtonHidden,
  ...props
}) {
  return (
    <ReactModal
      overlayClassName={`fixed bg-black/[.75] inset-0 z-20`}
      className={twMerge(
        `absolute-centered w-[300px] min-h-[64px] p-[20px] pt-[64px] bg-white rounded-[4px]`,
        className
      )}
      {...props}
    >
      {!closeButtonHidden && <Close onClick={props.onRequestClose} />}
      {children}
    </ReactModal>
  )
}

function Close({ onClick }) {
  return (
    <div
      className="absolute top-[20px] right-[20px] fill-black cursor-pointer"
      onClick={onClick}
    >
      {<CloseIcon />}
    </div>
  )
}

export function useModal() {
  const [props, setProps] = useState()
  const [isOpen, setIsOpen] = useState(false)

  const open = props => {
    setIsOpen(true)
    setProps(props)
  }

  const close = () => {
    setIsOpen(false)
    setProps()
  }

  const onRequestClose = () => close()

  return {
    open,
    close,
    props: {
      isOpen,
      onRequestClose,
      ...(props || {}),
    },
  }
}
