import { useState } from "react"

export default function useAccordion() {
  const [active, setActive] = useState()

  const toggle = idx => {
    setActive(old => old !== idx ? idx : undefined)
  }

  return {
    active,
    toggle
  }
}