import ChapterController from "@api/ChapterController"
import { useQuery } from "react-query"

export default function useChapterByIdQuery(subjectId, chapterId) {
  return useQuery(`chapter-${chapterId}`, () =>
    ChapterController.getChapters({ subjectId }).then(chapters =>
      chapters.data.find(chapter => chapter.id === parseInt(chapterId))
    )
  )
}
