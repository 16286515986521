import Stars from "@blocks/Stars"
import TextArea from "@components/form/TextArea"
import Container from "@components/layout/Container"
import useTitle from "@hooks/useTitle"
import PageTemplate from "@templates/PageTemplate"
import { ReactComponent as MicroscopeIcon } from "@icons/microscope.svg"
import Button from "@components/form/Button"
import useCategory from "@hooks/useCategory"
import { useParams } from "react-router-dom"
import ErrorPageTemplate from "@templates/ErrorPageTemplate"
import { useForm } from "react-hook-form"
import LessonController from "@api/LessonController"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { store } from "@redux/store"
import { mutateSubscription } from "@redux/user.slice"
import withIcons from "@helpers/withIcons"
import { useState, useEffect } from "react"

export default function RatePage() {
  useTitle("Rate")
  useCategory("lessons")
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [lesson, setLesson] = useState(null)
  const [error, setError] = useState(null)

  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (id) {
      const getLesson = async () => {
        setIsLoading(true)
        try {
          const res = await withIcons(
            LessonController.getLesson({ lessonId: id }).then(
              response => response.data
            )
          )
          setLesson(res)
        } catch (err) {
          console.log("error message", err?.message)
          setError(err?.message)
        }
        setIsLoading(false)
      }
      getLesson()
    }
  }, [id])

  const sendRating = async data => {
    try {
      await LessonController.rateLesson({
        lessonId: id,
        rate: data.rate,
        text: data.text,
      })

      toast.success("Rating is sent. Thank you for your feedback.")
      setTimeout(() => {
        window.window.location.href = window.location.href.replace("/rate", "")
      }, 3000)
    } catch (err) {
      toast.error(err.message)
    }
  }

  if (isLoading) return null

  if (error === "Subscription is not active") {
    setTimeout(() => {
      store.dispatch(mutateSubscription(false))
      navigate("/subscription")
    }, 5000)
    return (
      <ErrorPageTemplate
        title="Subscription is not active"
        onClick={() => {
          store.dispatch(mutateSubscription(false))
          navigate("/subscription")
        }}
      >
        You should subscribe to see data here
      </ErrorPageTemplate>
    )
  }

  if (!lesson) {
    return (
      <ErrorPageTemplate title="Not found">
        Sorry, we can't find this particular test. Maybe, you got the broken
        link or this test was deleted.
      </ErrorPageTemplate>
    )
  }

  return (
    <Container.Narrow className="h-full">
      <PageTemplate>
        <form
          className="flex flex-col items-center"
          onSubmit={handleSubmit(sendRating)}
        >
          <div className="mb-[36px] flex flex-col items-center">
            <div className="flex items-center mb-[12px]">
              <div className="rounded-full centered bg-light-primary w-[30px] h-[30px] fill-primary mr-[8px]">
                <MicroscopeIcon />
              </div>
              <p className="font-bold text-primary">{lesson?.subject}</p>
            </div>
            <h2 className="mb-[10px]">{lesson?.name}</h2>
            <p className="text-typography-grey font-normal mb-[10px]">
              Rate this subject?
            </p>
            <Stars.Controller
              name="rate"
              control={control}
              error={errors?.rate}
              rules={{
                required: {
                  message: "Rating is required",
                  value: true,
                },
              }}
            />
          </div>
          <TextArea
            placeholder="Write your valoration"
            className="min-h-[240px]"
            error={errors?.text}
            {...register("text", {
              required: { value: true, message: "Feedback is required" },
            })}
          />
          <Button className="mt-[32px]">Submit</Button>
        </form>
      </PageTemplate>
    </Container.Narrow>
  )
}
