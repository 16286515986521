import Container from "@components/layout/Container"
import BorderlessNavButton from "@components/navigation/BorderlessNavButton"

export default function Footer({ navigation }) {
  return (
    <Container
      className={`
        flex 
        bg-white 
        space-x-[60px] 
        justify-center 
        pt-[24px] 
        pb-[12px]
      `}
    >
      {navigation.map(item => (
        <BorderlessNavButton key={item.label} {...item} />
      ))}
    </Container>
  )
}
